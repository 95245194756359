import React, { useState } from "react";
import { PropTypes } from "prop-types";
import styled, { keyframes } from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
import { DEFAULT_IMAGE } from "../Helpers/constants";

const WishCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 450px;
  height: 375px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 20px -6px rgba(168, 168, 168, 1);
  -moz-box-shadow: 5px 5px 20px -6px rgba(168, 168, 168, 1);
  box-shadow: 5px 5px 20px -6px rgba(168, 168, 168, 1);
  overflow: hidden;
  width: 100%;
`;
const ColoredSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  flex: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all ease-in-out 0.5s;
  background: ${props =>
    props.disabled
      ? COLOR_SCHEME.lightBg
      : props.color
      ? props.color
      : COLOR_SCHEME.primary};
`;

const WhiteSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${props => (props.isHovered ? "0" : "20px")};
  flex: 2;
  height: 100%;
  max-width: ${props => (props.isHovered ? "0" : "300px")};
  transform: ${props => (props.isHovered ? "scale(0.3)" : "scale(1)")};
  opacity: ${props => (props.isHovered ? 0 : 1)};
  transition: all ease-in-out 0.5s, opacity ease-in-out 0.2s;
  overflow: hidden;
`;

const WhiteText = styled.div`
  color: white;
  font-size: 1.2rem;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const WishDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: keep-all;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  max-height: 150px;
  margin-bottom: 0.5rem;
`;

const DarkText = styled.div`
  color: #333333;
  font-size: 1.2rem;
  font-weight: 600;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const WhiteTextSub = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
`;

const ColouredButton = styled.div`
  padding: 5px 5px;
  background: ${props =>
    props.disabled
      ? COLOR_SCHEME.lightBg
      : props.color
      ? props.color
      : COLOR_SCHEME.primaryHover};
  border-radius: 5px;
  &:hover {
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    box-shadow: 3px 3px 5px -3px rgba(168, 168, 168, 1);
    transform: ${props => (props.disabled ? "" : "translateY(-2px);")};
  }
  transition: all ease-in-out 0.3s;
  color: white;
`;

const WishImage = styled.img`
  border-radius: 5px;
  position: absolute;
  z-index: 20;
  top: ${props => (props.isHovered ? "20%" : "20%")};
  left: 20px;
  max-height: ${props => (props.isHovered ? "20%" : "43%")};
  max-width: ${props => (props.isHovered ? "20%" : "60%")};
  transition: all ease-in-out 0.3s;
  display: ${props => (props.loaded ? "block" : "none")};
  object-fit: cover;
  box-shadow: 2px 2px 10px -6px grey;
  @media (max-width: 425px) {
    top: 20%;
  }
`;
const KeyFramesGhostContainer = keyframes`
  0% {
    background: #e1e1e1;
  }
  50% {
    background: #f1f1f1;
  }
  100% {
    background: #e1e1e1;
  }
`;
const ImagePlaceholder = styled.div`
  position: absolute;
  z-index: 20;
  top: 20%;
  left: 20px;
  width: 250px;
  height: 150px;
  background: lightgrey;
  display: ${props => (props.loaded ? "none" : "block")};
  animation: ${KeyFramesGhostContainer} 1s ease-in-out 0s infinite;
`;
const SingleWish = ({ wish, showModal, setWishToReserve, color }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [isHovered, onHover] = useState(false);
  return (
    <WishCard
      key={wish.id}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <ColoredSection disabled={wish.reserved} color={color}>
        <WhiteText>{wish.price} CHF</WhiteText>
        <div>
          <WhiteText>
            {/*{isPreview
              ? 100
              : Math.floor((wish.availableVal / wish.price) * 100)}*/}
            {wish.availableVal} CHF
          </WhiteText>
          <WhiteTextSub>verfügbar</WhiteTextSub>
        </div>
      </ColoredSection>
      {/*      <WhiteSectionAbsolute>
        <WishTitleWhite>{wish.name}</WishTitleWhite>
        <WishDescription>{wish.description}</WishDescription>
        <ColouredButton
          onClick={() => {
            setWishToReserve(wish.id);
            showModal();
          }}
        >
          Reservieren
        </ColouredButton>
      </WhiteSectionAbsolute>*/}
      {wish.imageUrl ? (
        <>
          <ImagePlaceholder loaded={imgLoaded} />

          <WishImage
            //isHovered={isHovered}
            loaded={imgLoaded}
            onLoad={() => setImgLoaded(true)}
            src={wish.imageUrl}
          />
        </>
      ) : (
        <WishImage
          //isHovered={isHovered}
          loaded={true}
          src={DEFAULT_IMAGE}
        />
      )}
      <WhiteSection>
        <DarkText>{wish.name}</DarkText>
        <div>
          <WishDescription>{wish.description}</WishDescription>
          <ColouredButton
            color={color}
            disabled={wish.reserved}
            onClick={() => {
              if (!wish.reserved) {
                setWishToReserve(wish.id);
                showModal();
              }
            }}
          >
            Reservieren
          </ColouredButton>
        </div>
        {/* {wish.type === WISH_TYPES.FIXED_SHARES ? (
          <ReservationStatusText>
            <b>{wish.availableVal / (wish.price / wish.numberOfShares)}</b> von{" "}
            <b>{wish.numberOfShares}</b> Anteile verfügbar
          </ReservationStatusText>
        ) : null}*/}
      </WhiteSection>
    </WishCard>
  );
};

export default SingleWish;

SingleWish.propTypes = {
  wish: PropTypes.object.isRequired
};
