import { COLOR_SCHEME } from "./Colors";
import { getAlphaColor } from "../Helpers/getAlphaColor";

export const customThemeSelect = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#f8f9fa",
    primary50: getAlphaColor(COLOR_SCHEME.primary, 0.5),
    primary: COLOR_SCHEME.primary,
    text: "red"
  }
});
