import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { COLOR_SCHEME } from "../../Views/Colors";
import { firestore } from "../../../redux/store";
import { withRouter } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../Helpers/constants";

const ImageWrapper = styled.div`
  max-width: 100px;
  min-width: 75px;
  height: auto;
  img {
    object-fit: contain;
  }
`;

const Description = styled.td`
  max-width: 300px;
`;

const ActionIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    color: ${COLOR_SCHEME.primaryHover};
  }
`;
const WishOverviewEntry = props => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");
  const deleteWish = () => {
    setFetching(true);
    setError("");
    firestore
      .collection("wishes")
      .doc(props.wish.id)
      .update({ deleted: true })
      .then(() => setFetching(false))
      .catch(err => setError(err));
  };
  return (
    <tr>
      <td>
        <ImageWrapper>
          <img
            src={props.wish.imageUrl || DEFAULT_IMAGE}
            className={"img-fluid"}
          />
        </ImageWrapper>
      </td>
      <td>{props.wish.name}</td>
      <td>
        <b>{props.wish.price}</b>
      </td>
      <Description>{props.wish.description}</Description>
      <td>
        <ActionIcon
          icon={faPen}
          onClick={() =>
            props.history.push("/dashboard/add?p=add&wishId=" + props.wish.id)
          }
        />
        <ActionIcon icon={faTrash} onClick={() => deleteWish()} />
      </td>
    </tr>
  );
};

export default withRouter(WishOverviewEntry);
WishOverviewEntry.propTypes = {
  wish: PropTypes.object.isRequired
};
