import React, { useState } from "react";
import styled from "styled-components";
import { COLOR_SCHEME } from "../../Views/Colors";
import { InputLabel, TextInput } from "../../Helpers/FormComponents";
import Button from "../../Views/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../../redux/store";
import { useDocumentData } from "react-firebase-hooks/firestore";
import * as firebase from "firebase";

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 300px;
  margin: 20px 40px 0 0;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const CategoryWrapper = styled.div`
  position: relative;
`;
const Category = styled.div`
  padding: 10px;
  color: ${COLOR_SCHEME.primary};
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  &:hover {
    //box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const AddCategoryWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 0;
  min-width: 300px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 30%;
  right: 1rem;
  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
    color: ${COLOR_SCHEME.primary};
  }
  transition: all ease-in-out 0.2s;
`;
const DashboardCategories = ({ uid }) => {
  const [input, setInput] = useState("");
  const userRef = firestore.collection("wishlistRouting").doc(uid);
  const [snapshot, loading, error] = useDocumentData(userRef);

  const addCategory = () => {
    if (snapshot.categories) {
      firestore
        .collection("wishlistRouting")
        .doc(uid)
        .update({
          categories: firebase.firestore.FieldValue.arrayUnion(input)
        })
        .then(() => setInput(""));
    } else {
      firestore
        .collection("wishlistRouting")
        .doc(uid)
        .update({ categories: [input] })
        .then(() => setInput(""));
    }
  };

  const deleteCategory = category => {
    firestore
      .collection("wishlistRouting")
      .doc(uid)
      .update({
        categories: firebase.firestore.FieldValue.arrayRemove(category)
      })
      .then();
  };
  return (
    <>
      <h2>Kategorien verwalten</h2>
      <FlexWrapper>
        <AddCategoryWrapper>
          <h5>Kategorie hinzufügen</h5>
          <div>
            <InputLabel>Name</InputLabel>
            <TextInput
              value={input}
              placeholder={"Küche"}
              onChange={e => setInput(e.target.value)}
              disableMargin
            />
          </div>
          <Button
            onClick={addCategory}
            disabled={!input}
            color={COLOR_SCHEME.primary}
          >
            Kategorie erstellen
          </Button>
        </AddCategoryWrapper>
        <CategoryList>
          <h5>Kategorien</h5>
          {!loading &&
            snapshot.categories &&
            snapshot.categories.map((category, i) => (
              <CategoryWrapper key={i}>
                <Category>{category}</Category>
                <DeleteIcon
                  icon={faTimes}
                  onClick={() => deleteCategory(category)}
                />
              </CategoryWrapper>
            ))}
        </CategoryList>
      </FlexWrapper>
    </>
  );
};

export default DashboardCategories;
