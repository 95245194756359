import React from "react";
import Modal from "./Modal";

const AGBModal = ({ clickOutsideHandler }) => {
  return (
    <Modal
      title={"AGBs von Beschenkt.ch"}
      clickOutsideHandler={clickOutsideHandler}
    >
      <b>Allgemeine Geschäftsbedingungen von beschenkt.ch</b>

      <ol>
        <li>
          <b> Geltung der AGB</b>
          <ol>
            <li>
              Die allgemeinen Geschäftsbedingungen beruhen auf Schweizer Recht
              und gelten innerhalb der Schweiz, sofern die Parteien sie
              ausdrücklich oder stillschweigend aner-kennen.{" "}
            </li>
            <li>
              Die Geschäftsbedingungen treten mit der Registrierung auf
              beschenkt.ch in Kraft.
            </li>
            <li>
              Diese AGB gelten auf unbestimmte Zeit, solange sie nicht von den
              Parteien in schriftli-cher Vereinbarung geändert wurden.
            </li>
          </ol>
        </li>
        <li>
          <b>Datenschutz</b>
          <ol>
            <li>
              Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und
              die datenschutzrechtlichen Bestimmungen des Bundes
              (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer
              Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen
              Daten. Wir halten diese Bestimmungen ein. Persönliche Daten werden
              streng vertraulich behandelt und weder an Dritte verkauft noch
              weiter gegeben.
            </li>
            <li>
              In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir
              uns, die Datenbanken so gut wie möglich vor fremden Zugriffen,
              Verlusten, Missbrauch oder vor Fälschung zu schützen. Die vom
              Nutzer hochgeladenen Daten werden auf Servern der Firma Google
              innerhalb Europa gespeichert.
            </li>
            <li>
              Beim Zugriff auf unsere Webseiten werden folgende Daten in
              Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage
              und allg. übertragene Informationen zum Betriebssystem resp.
              Browser. Diese Nutzungsdaten bilden die Basis für statistische,
              anonyme Auswertungen, so dass Trends erkennbar sind, anhand derer
              wir unsere An-gebote entsprechend verbessern können.
            </li>
          </ol>
        </li>
        <li>
          <b>Urheberrechte</b>
          <ol>
            <li>
              Der Nutzer von beschenkt.ch bestätigt, dass er über sämtliche
              Urheberrechte der zu publizierenden Inhalte verfügt. Dies gilt
              insbesondere für Bilder die vom Nutzer hoch-geladen werden.
            </li>
            <li>
              Der Nutzer von beschenkt.ch verpflichtet sich, von sämtlichen
              abgebildeten oder erwähnten Personen vor der Publikation das
              Einverständnis zur Veröffentlichung einzuholen.
            </li>
          </ol>
        </li>
        <li>
          <b>Angebot</b>
          <ol>
            <li>
              Die Nutzung des Basisangebots ist kostenlos. Um alle Funktionen
              freizuschalten ist eine einmalige Aktivierung nötig.
            </li>
            <li>
              Die Wunschliste bleibt ab Aktivierung für mindestens 1 Jahr
              verfügbar. Beschenkt.ch behält sich das Recht vor, Wunschlisten
              die nach Ablauf von einem Jahr nicht mehr genutzt werden, zu
              löschen.
            </li>
          </ol>
        </li>
        <li>
          <b>Bezahlung & Preise</b>
          <ol>
            <li>
              Die Bezahlung erfolgt per Kreditkarte. Das gekaufte Angebot wird
              nach Eingang der Zahlung sofort freigeschaltet.
            </li>
            <li>
              Beschenkt.ch behält sich das Recht vor, die Preise zu ändern. Bei
              einem Vertragsabschluss sind die auf der Aktivierungsseite
              publizierten Preise verbindlich.
            </li>
          </ol>
        </li>
        <li>
          <b>Haftungsausschluss</b>
          <ol>
            <li>
              Beschenkt.ch übernimmt keine Haftung für von Nutzern publizierten
              Inhalten. Die Verantwortung für sämtliche auf einer Wunschliste
              von Beschenkt.ch publizierten Inhalten liegt beim Nutzer.
              Beschenkt.ch behält sich das Recht vor, Wunschlisten mit
              widerrechtlichen, bedrohenden oder anderweitig verwerflichen
              Inhalten (nach Meinung von Beschenkt.ch) ohne Rückerstattung zu
              löschen.
            </li>
            <li>
              Beschenkt.ch lehnt jede Haftung für Ausfälle bzw. Ausfälle, die
              durch Drittanbieter mit denen Beschenkt.ch zusammenarbeitet
              entstehen, ab. Es können ausserdem Unterbrüche für
              Wartungsarbeiten entstehen.
            </li>
          </ol>
        </li>
        <li>
          <b>Gerichtsstand</b>
          <ol>
            <li>
              Ausschliesslicher Gerichtsstand für alle Streitigkeiten ist Aarau,
              Schweiz.
            </li>
          </ol>
        </li>
      </ol>
    </Modal>
  );
};

export const withAGBModal = WrappedComponent => {
  class HOC extends React.Component {
    state = { show: false };
    render() {
      return (
        <>
          {this.state.show && (
            <AGBModal
              clickOutsideHandler={() => this.setState({ show: false })}
            />
          )}
          <WrappedComponent showAGB={() => this.setState({ show: true })} />
        </>
      );
    }
  }
  return HOC;
};
