import React, { useState, useEffect } from "react";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import * as firebase from "firebase";
import styled from "styled-components";
import Button from "../../Views/Button";
import { StyledLink } from "../../Views/Link";
import { ErrorHandling } from "../../Helpers/ErrorHandling";
import { COLOR_SCHEME } from "../../Views/Colors";
import MainNavigation from "../../Navigation/MainNav";
import {
  ImageWrapper,
  LoginWrapper,
  StyledImage,
  TextInput,
  TextLink,
  Wrapper
} from "../SignUp/SignUp";
import { NoScroll } from "../../Helpers/NoScroll";

const ForgotPasswordLink = styled(Link)`
  text-align: right;
  margin: 0;
  width: calc(100% - 20px);
  max-width: 350px;
  font-size: 0.875rem;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;
const LoginComponent = props => {
  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
    password2: ""
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgPath, setImgPath] = useState(
    "/media/signIn-" + Math.ceil(Math.random() * 3) + ".svg"
  );

  const handleChange = e => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };
  const login = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(formInput.email, formInput.password)
      .then(() => setLoading(false))
      .catch(err => {
        setLoading(false);
        setError(ErrorHandling(err.code));
      });
  };
  useEffect(() => {
    // Redirect if logged in
    if (props.auth && props.auth.uid) {
      props.history.push("/dashboard");
    }
  });
  const [menuHeight, setMenuHeight] = useState(0);

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Wrapper>
        <ImageWrapper>
          <StyledImage src={imgPath} alt="register" />
        </ImageWrapper>
        <LoginWrapper>
          <h3>Login</h3>

          {error ? (
            <p
              style={{
                padding: "5px 10px",
                background: COLOR_SCHEME.primaryHover,
                color: "#fff"
              }}
            >
              {error}
            </p>
          ) : null}
          <TextInput
            id="username_input"
            placeholder="Email"
            name="email"
            value={formInput.username}
            onChange={handleChange}
            margin="normal"
          />
          <TextInput
            id="password_input"
            placeholder="Password"
            type="password"
            name="password"
            value={formInput.password}
            onChange={handleChange}
            onKeyPress={event => (event.key === "Enter" ? login() : null)}
          />
          <ForgotPasswordLink to={"/auth/forgot-password"}>
            Passwort vergessen?
          </ForgotPasswordLink>
          <Button text="Login" loading={loading} onClick={login} width={350} />
          <TextLink>
            Noch kein Konto?{" "}
            <StyledLink to={"/auth/register"}>
              Hier geht's zur Registrierung
            </StyledLink>
          </TextLink>
        </LoginWrapper>
      </Wrapper>
    </NoScroll>
  );
};

export default compose(
  withRouter,
  firebaseConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth
    }),
    dispatch => ({
      //addWish: (wish) => dispatch(createWish(wish))
    })
  )
)(LoginComponent);
