import React from "react";
import Wishlist from "./WishlistView";
import { firestore } from "../../redux/store";
import { Loader } from "../Views/Loader";
import Hero from "../Views/Hero";
import MainNavigation from "../Navigation/MainNav";
import { useCollectionData } from "react-firebase-hooks/firestore";

const WishlistRouteWrapper = props => {
  const wishlistRef = firestore
    .collection("wishlistRouting")
    .where("listCode", "==", props.match.params.listCode);
  const [snapshot, loading, error] = useCollectionData(wishlistRef);

  const routeDoc = snapshot && snapshot.length > 0 ? snapshot[0] : null;
  return routeDoc && routeDoc.uid && !routeDoc.deleted ? (
    <Wishlist
      uid={routeDoc.uid}
      wishlistTitle={routeDoc.title}
      color={routeDoc.color}
      categories={routeDoc.categories}
      description={routeDoc.description}
      image={routeDoc.image}
    />
  ) : loading ? (
    <Loader />
  ) : (
    <>
      <MainNavigation disableMenu transparent={true} />
      <Hero
        title="Hoppla!"
        subtitle="Hier gibt's leider keine Wunschliste..."
      />
    </>
  );
};

export default WishlistRouteWrapper;
