import React from "react";
import { firestore } from "../../redux/store";
import styled from "styled-components";
import LottieManager from "./LottieManager";
import { withRouter } from "react-router-dom";
const animationData = require("../../assets/animations/error500.json");

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  text-align: center;
  max-width: 500px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log("error", info, error.message, error.name);
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    firestore
      .collection("errorLoggerFE")
      .add({
        type: error.name,
        message: error.message,
        url: this.props.location.pathname,
        date: new Date()
      })
      .then(() => console.log("logged error"));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <h2 style={{ textAlign: "center" }}>
            Whoops, etwas hat nicht funktioniert😢
          </h2>
          <LottieManager animationData={animationData} width={200} />
          <Text>
            Wir haben die Fehlermeldung gespeichert und versuchen den Fehler
            möglichst bald zu beheben. Schreib uns doch auf{" "}
            <a href={"mailto:info@beschenkt.ch"}>info@beschenkt.ch</a> wenn du
            Hilfe benötigst.
          </Text>
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
