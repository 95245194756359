import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { COLOR_SCHEME } from "./Colors";

const HeroWrapper = styled.div`
  background: ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  width: 100%;
  min-height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 425px) {
    margin-top: 40px;
  }
`;
const MainTitle = styled.div`
  font-size: 3em;
  color: white;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
  }
`;
const SubTitle = styled.div`
  font-size: 1.5em;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  padding: 1rem;
`;
const Hero = ({ title, subtitle, color }) => {
  return (
    <HeroWrapper color={color}>
      {title ? <MainTitle>{title}</MainTitle> : null}
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
    </HeroWrapper>
  );
};

export default Hero;

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
