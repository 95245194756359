import React from "react";
import { compose } from "redux";
import { createWish, editWish } from "../../../redux/actions/WishActions";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import * as firebase from "firebase";
import styled from "styled-components";
import Button from "../../Views/Button";
import { COLOR_SCHEME } from "../../Views/Colors";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import ToastService from "../../Helpers/Toast";
import { WISH_TYPES, WISH_TYPES_LABELS } from "../../Helpers/constants";
import { Link, withRouter } from "react-router-dom";
import { firestore } from "../../../redux/store";
import Select from "react-select";
import { customThemeSelect } from "../../Views/StyledSelect";

const queryString = require("query-string");

const StyledSelect = styled(Select)`
  height: 50px;
  .react-select__control {
    height: 50px;
  }
`;
const TextAreaInput = styled.textarea`
  width: 100%;
  border-radius: 0.125rem;
  padding: 15px;
  margin: 5px 10px 10px 0;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
`;

const InputLabel = styled.div`
  font-size: 0.875rem;
  color: ${COLOR_SCHEME.light};
`;

export const StyledDropzone = styled(Dropzone)`
  width: 100px;
`;

const __GRAY_300 = "#e3e3e3";
const __GRAY_500 = "#adb5bd";
const __FIFTH = "#5e72e4";

const DropperContent = styled.div`
  color: ${__GRAY_300};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease-in-out;
`;

export const Dropper = styled.div`
  &:hover {
    border: 2px dashed ${__GRAY_500};
    & ${DropperContent} {
      color: ${__GRAY_500};
      & > svg {
        fill: ${__GRAY_500};
      }
    }
  }

  & {
    ${props =>
      props.isDragActive
        ? `
          border: 2px dashed ${__FIFTH};
           & ${DropperContent} {
              color: ${__FIFTH};   
               & > svg {
                  fill: ${__FIFTH};
               }
           }
          `
        : null};
  }
  border: 2px dashed ${__GRAY_300};
  padding: 0 13px 0 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  border-radius: 6px;
  min-height: 80px;
  max-height: 100px;
  height: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: ${COLOR_SCHEME.light};
  margin-right: 2rem;
  @media (max-width: 450px) {
    margin: 0;
  }
`;

const FormWrapper = styled.div`
  flex: 2;
`;
export const ProgressbarWrapper = styled.div`
  height: 0.5rem;
  width: calc(100% + 30px);
`;
export const Progressbar = styled.div`
  width: ${props => (props.progress ? props.progress + "%" : 0)}
  height: 100%;
  background: ${COLOR_SCHEME.primaryGradient};
  border-radius: .125rem;
  transition: width ease-in-out .2s;
`;
const FormTop = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FlexOneContainer = styled.div`
  flex: 1;
  margin: 0.5rem 2rem 0 0;
  @media (max-width: 450px) {
    margin: 0;
  }
  min-width: 350px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  max-width: 400px;
  img {
    object-fit: contain;
  }
  div {
    position: absolute;
    content: "X";
    top: 0;
    right: 0;
    font-size: 1.2rem;
    line-height: 1rem;
    padding: 2px;
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const DeleteIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  color: ${COLOR_SCHEME.primary};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HoverDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

class CreateWish extends React.Component {
  state = {
    editMode: false,
    wish: {
      name: "",
      description: "",
      price: "",
      imageUrl: "",
      type: WISH_TYPES.FLEXIBLE_SHARES
    },
    routingDoc: {},
    uploading: false
  };
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.wishId) {
      this.setState({ editMode: true });
      firestore
        .collection("wishes")
        .doc(params.wishId)
        .get()
        .then(doc => {
          if (doc.exists) {
            const {
              name,
              description,
              price,
              imageUrl,
              type,
              category
            } = doc.data();
            this.setState({
              wish: {
                name,
                description,
                price,
                imageUrl,
                type,
                id: params.wishId,
                category: category ? category : ""
              }
            });
          } else {
            // exit edit mode if id is invalid
            delete params.wishId;
            this.props.history.push(
              "/dashboard?" + queryString.stringify(params)
            );
          }
        })
        .catch(err => console.log(err));
    }

    firestore
      .collection("wishlistRouting")
      .doc(this.props.uid)
      .get()
      .then(doc => this.setState({ routingDoc: doc.data() }))
      .catch(err => console.log);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Clear form if wish got created successfully
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      if (!this.props.error) {
        ToastService.raiseSuccess(
          `Wunsch ${this.state.wish.name} ${
            this.state.editMode ? " editiert." : " erfolgreich hinzugefügt!"
          }`
        );
        this.setState({
          wish: { name: "", description: "", price: "", imageUrl: "" }
        });
        this.props.navigate("WISHLIST");
      } else {
        ToastService.raiseWarn(this.props.error);
      }
    }
  }

  onChangeInput = e => {
    const { name, value } = e.target;
    this.setState(prevState => {
      return {
        wish: { ...prevState.wish, [name]: value }
      };
    });
  };

  handleFileChange = file => {
    this.setState({ uploading: true });
    const storage = firebase.storage();
    const uploadRef = storage.ref().child(`uploads/${Date.now()}-${file.name}`);
    const uploadTask = uploadRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ progress });
      },
      error => {
        // Handle unsuccessful uploads
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.setState(prevState => ({
            wish: { ...prevState.wish, imageUrl: downloadURL },
            uploading: false,
            progress: 0
          }));
        });
      }
    );
  };
  createWish = () => {
    this.props.addWish({
      ...this.state.wish,
      sharesAmount:
        this.state.wish.price && this.state.wish.numberOfShares
          ? this.state.wish.price / this.state.wish.numberOfShares
          : null
    });
  };

  editWish = () => {
    this.props.editWish(this.state.wish);
    this.removeParams();
  };

  removeParams = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.wishId) {
      delete params.wishId;
    }
    this.props.history.push(
      this.props.location.pathname + "?" + queryString.stringify(params)
    );
  };

  setType = type => {
    this.setState(prevState => ({ wish: { ...prevState.wish, type } }));
  };

  removeImage = () => {
    const storage = firebase.storage();
    const ref = storage.refFromURL(this.state.wish.imageUrl);
    ref
      .delete()
      .then(() => {
        console.log("delete successful!");
        this.setState(prevState => ({
          wish: { ...prevState.wish, imageUrl: "" }
        }));
      })
      .catch(err => {
        if (err.code === "storage/object-not-found") {
          this.setState(prevState => ({
            wish: { ...prevState.wish, imageUrl: "" }
          }));
        }
      });
  };

  render() {
    return (
      <>
        <FormWrapper>
          <h2>Wunsch erstellen</h2>
          <div>
            <FormTop>
              <FlexOneContainer>
                <InputLabel>Titel</InputLabel>
                <input
                  placeholder="Kaffeemaschine"
                  className={"form-control"}
                  type="text"
                  name="name"
                  value={this.state.wish.name}
                  onChange={this.onChangeInput}
                />
              </FlexOneContainer>
              <FlexOneContainer>
                <InputLabel>Preis</InputLabel>
                <div className="input-group wish-type-toggle">
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    placeholder={"199"}
                    onChange={this.onChangeInput}
                    value={this.state.wish.price}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {WISH_TYPES_LABELS[this.state.wish.type]}
                    </button>
                    <div className="dropdown-menu">
                      <HoverDiv
                        className="dropdown-item"
                        onClick={() => this.setType(WISH_TYPES.FLEXIBLE_SHARES)}
                      >
                        Flexible Anteile
                      </HoverDiv>
                      <HoverDiv
                        className="dropdown-item"
                        onClick={() => this.setType(WISH_TYPES.NON_MONETARY)}
                      >
                        Sachgeschenk
                      </HoverDiv>
                    </div>
                  </div>
                </div>
              </FlexOneContainer>
              <FlexOneContainer>
                <InputLabel>Kategorie</InputLabel>

                {this.state.routingDoc.categories &&
                this.state.routingDoc.categories.length > 0 ? (
                  <StyledSelect
                    options={
                      this.state.routingDoc.categories
                        ? this.state.routingDoc.categories.map(category => ({
                            label: category,
                            value: category.toLowerCase()
                          }))
                        : null
                    }
                    value={
                      this.state.routingDoc.categories
                        ? this.state.routingDoc.categories
                            .filter(
                              category =>
                                category.toLowerCase() ===
                                this.state.wish.category
                            )
                            .map(category => ({
                              label: category,
                              value: category.toLowerCase()
                            }))
                        : null
                    }
                    onChange={({ value }) =>
                      this.setState(prevState => ({
                        wish: { ...prevState.wish, category: value }
                      }))
                    }
                    theme={customThemeSelect}
                    classNamePrefix="react-select"
                    placeholder={"Kategorie auswählen"}
                  />
                ) : (
                  <div>
                    Keine Kategorien gefunden.{" "}
                    <Link to={"/dashboard/categories"}>
                      Erstelle deine erste Kategorie
                    </Link>
                  </div>
                )}
              </FlexOneContainer>
            </FormTop>
            <FlexOneContainer>
              <InputLabel>Beschreibung</InputLabel>
              <TextAreaInput
                value={this.state.wish.description}
                placeholder="...damit wir am Morgen aus den Federn kommen :)"
                type="textarea"
                name="description"
                onChange={this.onChangeInput}
              />
            </FlexOneContainer>
            {/* {this.state.wish.type === WISH_TYPES.FIXED_SHARES ? (
              <div className={"d-flex flex-wrap"}>
                <FlexOneContainer>
                  <InputLabel>Anzahl Anteile</InputLabel>
                  <TextInput
                    value={this.state.wish.numberOfShares}
                    type="number"
                    name="numberOfShares"
                    onChange={this.onChangeInput}
                  />
                </FlexOneContainer>
                <FlexOneContainer>
                  <InputLabel>à</InputLabel>
                  <TextInput
                    disabled={true}
                    value={
                      this.state.wish.price && this.state.wish.numberOfShares
                        ? this.state.wish.price / this.state.wish.numberOfShares
                        : null
                    }
                    type="number"
                    name="sharesAmount"
                    onChange={this.onChangeInput}
                  />
                </FlexOneContainer>
              </div>
            ) : null}*/}
            {this.state.wish.imageUrl ? (
              <ImageWrapper>
                <img className={"img-fluid"} src={this.state.wish.imageUrl} />
                <DeleteIcon onClick={this.removeImage}>X</DeleteIcon>
              </ImageWrapper>
            ) : (
              <StyledDropzone
                onDrop={acceptedFiles =>
                  this.handleFileChange(acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Dropper {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <div>Bild hierhin ziehen oder klicken um auszuwählen</div>
                  </Dropper>
                )}
              </StyledDropzone>
            )}
            {this.state.uploading ? (
              this.state.progress < 100 ? (
                <ProgressbarWrapper>
                  <Progressbar progress={this.state.progress} />
                </ProgressbarWrapper>
              ) : (
                <p>Verarbeite...</p>
              )
            ) : null}
            <Button
              className={"mt-2"}
              width={200}
              text={this.state.editMode ? "Speichern" : "Wunsch erstellen"}
              loading={this.props.loading}
              onClick={() =>
                this.state.editMode ? this.editWish() : this.createWish()
              }
            />
          </div>
        </FormWrapper>
        {/*        <PreviewWrapper>
          <h2>Vorschau</h2>
          <SingleWish wish={this.state.wish} isPreview={true} />
        </PreviewWrapper>*/}
      </>
    );
  }
}

export default compose(
  withRouter,
  firebaseConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      loading: state.wishes.loading,
      error: state.wishes.error
    }),
    dispatch => ({
      addWish: wish => dispatch(createWish(wish)),
      editWish: wish => dispatch(editWish(wish))
    })
  )
)(CreateWish);
