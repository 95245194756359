import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import {
  reactReduxFirebase,
  firebaseReducer,
  getFirebase
} from "react-redux-firebase";
import {
  reduxFirestore,
  firestoreReducer,
  getFirestore
} from "redux-firestore";
import { firebaseConfig } from "../config";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { wishReducer } from "./reducers/WishReducer";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

// Add firebase and firestore to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  wishes: wishReducer
});

// Create store with reducers and initial state
const initialState = {};

let store;
if (process.env.NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        thunk.withExtraArgument({ getFirebase, getFirestore }) // Pass getFirebase function as extra argument
      ),
      reactReduxFirebase(firebase, rrfConfig),

      reduxFirestore(firebase),
      composeWithDevTools()
    )
  );
} else {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        thunk.withExtraArgument({ getFirebase, getFirestore }) // Pass getFirebase function as extra argument
      ),
      reactReduxFirebase(firebase, rrfConfig),

      reduxFirestore(firebase)
    )
  );
}

export default store;
