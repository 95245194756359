import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import TutorialFooter from "../TutorialFooter";
import { COLOR_SCHEME } from "../../../Views/Colors";

// Lottie
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: require("../../../../assets/animations/gift.json")
};

export const Text = styled.div`
  color: ${COLOR_SCHEME.dark};
  text-align: center;
`;
const TutorialWelcome = ({ next }) => {
  return (
    <>
      <h2>Willkommen bei Beschenkt.ch</h2>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
        isPaused={false}
        isStopped={false}
      />
      <Text>
        Eine Wunschliste erstellen ist ganz einfach. <br />
        Wir helfen dir, deine Wunschliste mit ein paar wenigen Schritten
        einzurichten
      </Text>
      <TutorialFooter onClick={next} />
    </>
  );
};

export default TutorialWelcome;
