import React, { useEffect, useState } from "react";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import * as firebase from "firebase";
import styled from "styled-components";
import Button from "../../Views/Button";
import { StyledLink } from "../../Views/Link";
import { COLOR_SCHEME } from "../../Views/Colors";
import { ErrorHandling } from "../../Helpers/ErrorHandling";
import MainNavigation from "../../Navigation/MainNav";
import { Checkbox } from "../../Helpers/FormComponents";
import { withAGBModal } from "../../Modals/AGB";
import { NoScroll } from "../../Helpers/NoScroll";

export const Wrapper = styled.div`
  display: flex;
  padding-top: 180px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
  margin-bottom: 80px;
`;
export const LoginWrapper = styled.div`
  flex: 7;
  padding: 30px 0 0 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 300px;
  @media (max-width: 768px) {
    min-width: 100%;
    align-items: center;
    padding: 0;
  }
`;

export const TextInput = styled.input`
  width: 350px;
  border-radius: 0.125rem;
  padding: 15px;
  margin: 10px 0;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const StyledImage = styled.img`
  max-width: 400px;
  height: auto;
  width: 100%;
  margin-left: 50px;
  @media (max-width: 768px) {
    max-width: 200px;
    margin: 0 0 30px 0;
    min-width: 100px;
  }
`;
export const ImageWrapper = styled.div`
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const TextLink = styled.div`
  margin-top: 0.5rem;
  max-width: 350px;
  @media (max-width: 768px) {
    max-width: 300px;
    text-align: center;
  }
`;
const SignUp = props => {
  const [agbChecked, checkAgb] = useState(false);
  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
    password2: ""
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  const register = () => {
    if (formInput.password !== formInput.password2) {
      setError("Passwörter stimmen nicht überein.");
      return;
    }
    if (!formInput.password2 || !formInput.password || !formInput.email) {
      setError("Bitte alle Felder ausfüllen");
      return;
    }
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(formInput.email, formInput.password)
      .then(() => setLoading(false))
      .catch(err => {
        setLoading(false);
        setError(ErrorHandling(err.code));
      });
  };

  useEffect(() => {
    // Redirect if logged in
    if (props.auth && props.auth.uid) {
      props.history.push("/dashboard");
    }
  });
  const [menuHeight, setMenuHeight] = useState(0);

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      {/*<Hero title={""} />*/}
      <Wrapper>
        <ImageWrapper>
          <StyledImage src="/media/register-illustration.svg" alt="register" />
        </ImageWrapper>
        <LoginWrapper>
          <h3>Erstelle dein Konto</h3>
          {error ? (
            <p
              style={{
                padding: "5px 10px",
                background: COLOR_SCHEME.primaryHover,
                color: "#fff"
              }}
            >
              {error}
            </p>
          ) : null}
          <TextInput
            id="username_input"
            placeholder="Email"
            name="email"
            value={formInput.username}
            onChange={handleChange}
            margin="normal"
          />
          <TextInput
            id="password_input"
            placeholder="Passwort"
            type="password"
            name="password"
            value={formInput.password}
            onChange={handleChange}
          />
          <TextInput
            id="password_repeat"
            placeholder="Passwort wiederholen"
            type="password"
            name="password2"
            value={formInput.password2}
            onChange={handleChange}
          />
          <Checkbox
            onChange={agb => checkAgb(!agbChecked)}
            defaultValue={agbChecked}
          >
            <p style={{ margin: 0 }}>
              Ich akzeptiere die{" "}
              <a href={"#"} onClick={() => props.showAGB()}>
                AGBs
              </a>
            </p>
          </Checkbox>
          <Button
            width={350}
            text="Kostenlos registrieren"
            loading={loading}
            onClick={register}
            disabled={
              !agbChecked ||
              !formInput.email ||
              !formInput.password ||
              !formInput.password2
            }
          />
          <TextLink>
            Bereits registriert?
            <StyledLink to={"/auth/login"}> Hier geht's zum Login</StyledLink>
          </TextLink>
        </LoginWrapper>
      </Wrapper>
    </NoScroll>
  );
};

export default compose(
  withAGBModal,
  withRouter,
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(SignUp);
