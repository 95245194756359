import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import HomeComponent from "../components/Home/HomeComponent";
import LoginComponent from "../components/Auth/Login/Login";
import DashboardComponent from "../components/Dashboard/DashboardComponent";
import WishlistRouteWrapper from "../components/Wishlist/WishlistRouteWrapper";
import SignUp from "../components/Auth/SignUp/SignUp";
import ForgotPassword from "../components/Auth/ForgotPassword/ForgotPassword";
import NotFound from "./NotFound";
import ResetPassword from "../components/Auth/ResetPassword/resetPassword";
import Impressum from "../components/Home/Impressum";
import FAQ from "../components/Home/faq";

const MainRouter = () => {
  return (
    <Switch>
      <PrivateRoute path="/dashboard/:subPage" component={DashboardComponent} />
      <PrivateRoute path="/dashboard" component={DashboardComponent} />
      <Route path="/about/impressum" exact={true} component={Impressum} />
      <Route path="/about/faq" exact={true} component={FAQ} />
      <Route path="/auth/login" component={LoginComponent} />
      <Route path="/auth/register" component={SignUp} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/reset-password" component={ResetPassword} />
      <Route path="/" exact={true} component={HomeComponent} />
      <Route path="/:listCode" exact={true} component={WishlistRouteWrapper} />
      <Route path="/" component={NotFound} />
    </Switch>
  );
};

export default MainRouter;
