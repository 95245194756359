import React from "react";
import { connect } from "react-redux";
import { firestore } from "../../../redux/store";
import WishReservationEntry from "./WishReservationEntry";
import { Loader } from "../../Views/Loader";

class DashboardWishlist extends React.Component {
  state = {
    reservations: [],
    fetching: false
  };

  componentDidMount() {
    this.setState({ fetching: true });
    firestore
      .collectionGroup("reservations")
      .where("uid", "==", this.props.uid)
      .orderBy("createdAt")
      .get()
      .then(snapshot => {
        this.setState({
          fetching: false,
          reservations: snapshot.docs
            ? snapshot.docs.map(snap => ({ ...snap.data(), id: snap.id }))
            : []
        });
        console.log(snapshot);
      })
      .catch(error => {
        this.setState({ fetching: false, error });
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <h2>Reservierungen</h2>
        {this.state.reservations.length > 0 ? (
          <table className="table table-responsive table-hover">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">Wunsch</th>
                <th scope="col">Name</th>
                <th scope="col">Betrag</th>
                <th scope="col">Bemerkungen</th>
              </tr>
            </thead>

            <tbody>
              {this.state.reservations
                .filter(res => !res.deleted)
                .map((res, i) => (
                  <WishReservationEntry reservation={res} key={i} />
                ))}
            </tbody>
          </table>
        ) : this.state.fetching ? (
          <Loader />
        ) : (
          <div className={"mt-5"}>
            <b>Hier siehts ziemlich leer aus...</b>
            <br />
            Verteile jetzt deine Wunschliste, so dass schon bald deine ersten
            Wünsche reserviert werden!
          </div>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({
  uid: state.firebase.auth.uid
}))(DashboardWishlist);
