import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { LoaderBtn } from "./Loader";
import { COLOR_SCHEME } from "./Colors";
import { getAlphaColor } from "../Helpers/getAlphaColor";

const StyledButton = styled.div`
  color: #fff;
  margin-top: 10px;
  border-radius: 0.25rem;
  width: ${props => (props.width ? props.width + "px" : "100%")};
  padding: 10px 15px;
  background: ${props =>
    props.color
      ? props.disabled
        ? getAlphaColor(props.color, 0.7)
        : props.color
      : props.disabled
      ? getAlphaColor(COLOR_SCHEME.primary, 0.7)
      : COLOR_SCHEME.primary};
  &:hover {
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    transform: ${props => (props.disabled ? "none" : "translateY(-2px)")};
    box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.05);
  }
  transition: all ease-in-out 0.3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;
const Button = ({
  onClick,
  text,
  loading,
  disabled,
  color,
  children,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={() => (disabled || !onClick ? null : onClick())}
      {...rest}
      disabled={disabled}
      color={color}
    >
      {text}
      {children}
      {loading ? <LoaderBtn /> : null}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  loading: PropTypes.bool
};
