import React, { useState } from "react";
import styled from "styled-components";
import { Fade, Zoom } from "react-reveal";
import TutorialWelcome from "./Slides/Welcome";
import TutorialPersonalDetails from "./Slides/PersonalDetails";
import { useWindowSize } from "../../Helpers/windowSizeHook";
import TutorialWishlistInformation from "./Slides/WishlistInformation";
import TutorialWishlistRouting from "./Slides/WishlistRouting";

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  overflow: hidden;
  z-index: 1;
`;

const TutorialWrapper = styled.div`
  text-align: center;
  background: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  min-height: 500px;
  overflow: hidden;
  @media (max-width: 425px) {
    padding: 30px 10px;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    overflow: unset;
    overflow-y: scroll;
    display: block;
  }
`;

const TutorialComponent = ({ uid }) => {
  const [slide, setSlide] = useState(0);
  const [closed, setClosed] = useState(false);
  const next = () => {
    if (Slides.length - 1 === slide) {
      setClosed(true);
      return;
    }
    setSlide(slide + 1);
  };

  const slideProps = { uid, next };
  const Slides = [
    <TutorialWelcome {...slideProps} />,
    <TutorialPersonalDetails {...slideProps} />,
    <TutorialWishlistInformation {...slideProps} />,
    <TutorialWishlistRouting {...slideProps} />
  ];
  const window = useWindowSize();

  return (
    !closed && (
      <Overlay>
        <Zoom>
          <TutorialWrapper height={window.height} width={window.width}>
            {Slides.map(
              (s, i) =>
                i === slide && (
                  <Fade right key={i}>
                    {s}
                  </Fade>
                )
            )}
          </TutorialWrapper>
        </Zoom>
      </Overlay>
    )
  );
};

export default TutorialComponent;
