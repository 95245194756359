import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default class ToastService {
    static raiseSuccess(message) {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    static raiseWarn(message) {
        toast.warn(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    static raiseError(message) {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER
        });
    }
}
