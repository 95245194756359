import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import {
  faBars,
  faClipboardList,
  faCreditCard,
  faGift,
  faMagic,
  faPlusCircle,
  faQuestionCircle,
  faShareAlt,
  faShareSquare,
  faTag,
  faTimes,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { firebaseConnect } from "react-redux-firebase";
import { COLOR_SCHEME } from "../Views/Colors";
import { actionTypes } from "redux-firestore";
import { connect } from "react-redux";
import { firestore } from "../../redux/store";
import { useDocumentData } from "react-firebase-hooks/firestore";

const SideNavigation = styled.div`
  position: sticky;
  top: 0;
  color: ${COLOR_SCHEME.white};
  border-right: #e3e3e3 1px solid;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  background-color: ${COLOR_SCHEME.primary};
  @media (max-width: 960px) {
    height: ${props => (props.toggle ? "100vh" : "3rem")};
    z-index: 1000;
    width: 100%;
  }
  @media (min-width: 960.05px) {
    height: 100vh;
  }
  transition: height ease-in-out 0.2s;
`;

const MobileNavigation = styled.div`
  position: relative;
  color: ${COLOR_SCHEME.white};
  border-right: #e3e3e3 1px solid;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_SCHEME.primary};
  height: 3rem;
  width: 100%;
`;

const MobileDropdown = styled.div`
  top: 3rem;
  position: absolute;
  width: 100%;
  max-height: ${props => (props.show ? "600px" : 0)};
  transition: all ease-in-out 0.2s;
  z-index: 1000;
  background-color: ${COLOR_SCHEME.primary};
  overflow: hidden;
`;

const SideNavigationItem = styled.div`
  padding: ${props => (props.mobile ? "15px 20px" : "20px")};
  &:hover {
    box-sizing: content-box;
    background: ${COLOR_SCHEME.primaryGradient};
    box-shadow: 0px 0px 1px 1px ${COLOR_SCHEME.primaryHover};
    padding-left: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  transition: all ease-in-out 0.2s;
`;

const SideNavIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const HamburgerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 10px;
  margin-top: 10px;
  svg:hover {
    cursor: pointer;
  }
`;

const Logo = styled.div`
  font-size: 1.3rem;
  color: white;
  width: 100%;
  padding: 20px 20px 30px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  img:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
  img {
    transition: all ease-in-out 0.3s;
  }
`;

const MobileLogo = styled.div`
  transform: translateY(-10px);
  margin-left: 1rem;
  max-width: 150px;
  object-fit: contain;
  img {
    width: 100%;
    height: auto;
  }
`;

const SideNav = props => {
  const [isMobile, toggleMobile] = useState(window.innerWidth < 960);
  const [showMenu, toggleMenu] = useState(false);
  const mobileRef = useRef(null);

  const userRef = firestore.collection("wishlistRouting").doc(props.uid);
  const [routeDoc, loading, error] = useDocumentData(userRef);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      toggleMobile(window.innerWidth <= 960);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateNav = to => {
    props.update(to);
  };

  const navigation = [
    { label: "Wünsche", key: "WISHLIST", icon: faGift },
    { label: "Wunsch erfassen", key: "ADD", icon: faPlusCircle },
    { label: "Kategorien", key: "CATEGORIES", icon: faTag },
    { label: "Reservierungen", key: "RESERVED", icon: faClipboardList },
    { label: "Personalisieren", key: "PERSONALIZE", icon: faMagic },
    {
      label: props.activated ? "Zugangscode" : "Aktivieren",
      key: "SETTINGS",
      icon: props.activated ? faShareAlt : faCreditCard
    },
    { label: "Konto", key: "USER", icon: faUser },
    { label: "Support", key: "SUPPORT", icon: faQuestionCircle }
  ];
  const handleClickOutside = event => {
    if (mobileRef.current && !mobileRef.current.contains(event.target))
      toggleMenu(false);
  };
  return (
    <>
      {isMobile && (
        <MobileNavigation ref={mobileRef}>
          <HamburgerMenu>
            <MobileLogo>
              <img
                onClick={() => props.history.push("/")}
                alt={"beschenkt.ch"}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/beschenkt-react.appspot.com/o/static%2Fbeschenkt-white-200.png?alt=media&token=5e364e49-035f-46fa-98e3-c4c1c2b23580"
                }
              />
            </MobileLogo>
            {!showMenu && (
              <SideNavIcon icon={faBars} onClick={() => toggleMenu(true)} />
            )}
            {showMenu && (
              <SideNavIcon icon={faTimes} onClick={() => toggleMenu(false)} />
            )}
          </HamburgerMenu>
          <MobileDropdown show={showMenu}>
            <SideNavigationItem
              onClick={() => props.history.push("/" + routeDoc.listCode)}
              mobile={true}
            >
              <SideNavIcon icon={faShareSquare} />
              Vorschau
            </SideNavigationItem>
            {navigation.map(nav => (
              <SideNavigationItem
                mobile={true}
                key={nav.key}
                onClick={() => {
                  updateNav(nav.key);
                  toggleMenu(false);
                }}
              >
                <SideNavIcon icon={nav.icon} />
                {nav.label}
              </SideNavigationItem>
            ))}
            <SideNavigationItem
              mobile={true}
              onClick={() =>
                props.firebase.logout().then(() => {
                  props.history.push("/");
                  props.clearData();
                })
              }
            >
              <SideNavIcon icon={faSignOutAlt} />
              Abmelden
            </SideNavigationItem>
          </MobileDropdown>
        </MobileNavigation>
      )}
      {!isMobile && (
        <SideNavigation>
          <Logo>
            <img
              onClick={() => props.history.push("/")}
              alt={"beschenkt.ch"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/beschenkt-react.appspot.com/o/static%2Fbeschenkt-white-200.png?alt=media&token=5e364e49-035f-46fa-98e3-c4c1c2b23580"
              }
            />
          </Logo>
          <SideNavigationItem
            onClick={() => props.history.push("/" + routeDoc.listCode)}
          >
            <SideNavIcon icon={faShareSquare} />
            Vorschau
          </SideNavigationItem>
          {navigation.map(nav => (
            <SideNavigationItem
              key={nav.key}
              onClick={() => updateNav(nav.key)}
            >
              <SideNavIcon icon={nav.icon} />
              {nav.label}
            </SideNavigationItem>
          ))}

          <SideNavigationItem
            onClick={() =>
              props.firebase.logout().then(() => {
                props.history.push("/");
                props.clearData();
              })
            }
          >
            <SideNavIcon icon={faSignOutAlt} />
            Abmelden
          </SideNavigationItem>
        </SideNavigation>
      )}
    </>
  );
};

export default compose(
  firebaseConnect(),
  withRouter,
  connect(null, dispatch => ({
    clearData: () => dispatch({ type: actionTypes.CLEAR_DATA })
  }))
)(SideNav);

SideNav.propTypes = {
  update: PropTypes.func.isRequired
};
