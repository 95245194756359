import React from "react";
import * as firebase from "firebase";
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import Button from "../Views/Button";
import { compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";

const PaymentWrapper = styled.div`
  background: ${COLOR_SCHEME.lightBg};
  padding: 2rem;
  @media (max-width: 468px) {
    padding: 0.5rem;
  }
`;

const ProductPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  border-bottom: 1px solid black;
`;

const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  color: ${COLOR_SCHEME.primary};
  margin-bottom: 50px;
`;

const AccountInfo = styled.div`
  margin-bottom: 50px;
`;

const Error = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: ${COLOR_SCHEME.primary};
  color: ${COLOR_SCHEME.white};
`;

const Notifcation = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: ${COLOR_SCHEME.secondary};
  color: ${COLOR_SCHEME.white};
`;

const handleBlur = () => {
  console.log("[blur]");
};
const handleChange = change => {
  console.log("[change]", change);
};
const handleFocus = () => {
  console.log("[focus]");
};
const handleReady = () => {
  console.log("[ready]");
};

const createOptions = fontSize => {
  return {
    style: {
      base: {
        fontSize: fontSize ? fontSize + "px" : "1rem",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

class _HandleCardPayment extends React.Component {
  state = {
    clientSecret: null,
    error: null,
    disabled: true,
    succeeded: false,
    processing: false,
    message: null
  };

  componentDidMount() {
    const createIntent = firebase
      .functions()
      .httpsCallable("stripeCreatePaymentIntent");

    createIntent({
      amount: 4900,
      currency: "chf",
      payment_method_types: ["card"]
    })
      .then(res => {
        this.setState({
          clientSecret: res.data.client_secret,
          disabled: false
        });
      })
      .catch(err => {
        this.setState({ error: err.message });
      });
  }

  handleSubmit = () => {
    if (this.props.stripe) {
      this.props.stripe
        .handleCardPayment(this.state.clientSecret)
        .then(payload => {
          if (payload.error) {
            this.setState({
              error: `Zahlung fehlgeschlagen: ${payload.error.message}`,
              disabled: false,
              processing: false
            });
            console.log("[error]", payload.error);
          } else {
            this.setState({
              succeeded: true,
              error: ""
            });
            this.props.showSuccess();
          }
        });
      this.setState({ disabled: true, processing: true });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <>
        {!this.state.succeeded && (
          <PaymentWrapper>
            <AccountInfo>
              Wunschliste aktivieren für Konto <b>{this.props.user.email}</b>
            </AccountInfo>
            <ProductPrice>
              <div>Aktivierung Wunschliste (einmalig)</div>
              <div>CHF 49.-</div>
            </ProductPrice>
            <Total>CHF 49.-</Total>
            <div>Kreditkarte</div>
            <CardElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions()}
            />

            <Button
              disabled={this.state.disabled}
              onClick={this.handleSubmit}
              style={{ maxWidth: "200px" }}
              text={
                this.state.processing
                  ? "Verarbeiten…"
                  : "Wunschliste aktivieren"
              }
            />
          </PaymentWrapper>
        )}
        {this.state.error && <Error>{this.state.error}</Error>}
        {this.state.message && <Notifcation>{this.state.message}</Notifcation>}
      </>
    );
  }
}

export const HandleCardPayment = compose(
  injectStripe,
  connect(state => ({ user: state.firebase.auth }))
)(_HandleCardPayment);

export const Payment = props => {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
      <Elements>
        <HandleCardPayment {...props} />
      </Elements>
    </StripeProvider>
  );
};
