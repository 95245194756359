export const ErrorHandling = errorCode => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "Diese Email ist nicht gültig";
    case "auth/user-disabled":
      return "Dieser Benutzer wurde deaktiviert";
    case "auth/user-not-found":
      return "Wir konnten keinen Benutzer mit dieser E-Mail Adresse finden";
    case "auth/wrong-password":
      return "Password nicht korrekt";
    case "auth/weak-password":
      return "Das gewählte Passwort ist zu schwach";
      case "auth/email-already-in-use":
      return "Diese E-Mail Adresse ist bereits registriert";
    default:
      return "Uups, irgendwas ist schief gelaufen. Versuch es später nochmals";
  }
};
