import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Loader } from "../../components/Views/Loader";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoaded ? (
          !!auth.uid ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth/login" />
          )
        ) : (
          <Loader />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(mapStateToProps)(PrivateRoute);
