import React from "react";
import Button from "../../Views/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { LoaderBtn } from "../../Views/Loader";

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TutorialFooter = ({ onClick, isLast, loading }) => {
  return (
    <ButtonWrapper>
      <div />
      <Button width={120} onClick={onClick}>
        {isLast ? "Los gehts" : "Weiter"}{" "}
        {loading ? (
          <LoaderBtn style={{ transform: "translateY(3px)" }} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </Button>
    </ButtonWrapper>
  );
};

export default TutorialFooter;
