import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { COLOR_SCHEME } from "../Views/Colors";

const HeroWrapper = styled.div`
  background: ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
`;
const MainTitle = styled.div`
  font-size: 3em;
  color: white;
  font-weight: 300;
  margin-top: 30px;
  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
  }
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.single ? "center" : "flex-start")};
  width: 100%;
  max-width: 1400px;
  padding: 2rem;
  @media (max-width: 425px) {
    padding: 0 2rem;
    justify-content: center;
  }
`;
const SubTitle = styled.div`
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  padding: 1rem;
  min-width: 300px;
  flex: 1;
  text-align: ${props => (props.single ? "center" : "left")};
  @media (max-width: 425px) {
    padding: 0;
    font-size: 1.3rem;
  }
`;
const WishlistImageWrapper = styled.div`
  max-width: 400px;
  min-width: 300px;
  max-height: 400px;
  margin-bottom: 20px;
  flex: 1;
  object-fit: contain;
  padding: 1rem 0;
  @media (max-width: 425px) {
    padding: 0;
    min-width: 250px;
    max-width: 280px;
  }
`;
const WishlistImage = styled.img`
  width: 100%;
  height: auto;
  box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);
`;
const WishlistHero = ({ title, description, color, image }) => {
  return (
    <HeroWrapper color={color}>
      {title ? <MainTitle>{title}</MainTitle> : null}
      <DescriptionWrapper single={!image || !description}>
        {image && (
          <WishlistImageWrapper>
            <WishlistImage src={image} />
          </WishlistImageWrapper>
        )}
        {description && (
          <SubTitle single={!image || !description}>
            {description.split("\n").map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </SubTitle>
        )}
      </DescriptionWrapper>
    </HeroWrapper>
  );
};

export default WishlistHero;
