import React, { Component } from "react";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
import {
  InputLabel,
  TextAreaInput,
  TextInput
} from "../Helpers/FormComponents";
import Button from "../Views/Button";
import { createReservation } from "../../redux/actions/WishActions";
import { connect } from "react-redux";
import { DEFAULT_IMAGE, WISH_TYPES } from "../Helpers/constants";
import { validateEmail } from "../Helpers/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const WishInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const WishImageWrapper = styled.div`
  flex: 2;
  position: relative;
  max-width: 400px;
`;
const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
`;
const WishInfo = styled.div`
  flex: 5;
  padding-left: 20px;
  color: ${COLOR_SCHEME.light};
  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-left: 0;
  }
`;

const ReservationWrapper = styled.div`
  margin-top: 1rem;
`;

const NotificationBar = styled.div`
  width: 100%;
  background-color: ${props =>
    props.success ? COLOR_SCHEME.success : COLOR_SCHEME.danger};
  font-size: 1rem;
  color: white;
  border-radius: 0.25rem;
  padding: 10px;
  opacity: 0.9;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputHalf = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 50%;
    padding-right: ${props => (props.right ? 0 : "10px")};
  }
`;

const InputFull = styled.div`
  width: 100%;
`;
class WishReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInput: {},
      success: false,
      errors: ""
    };
  }
  handleFormChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      formInput: { ...prevState.formInput, [name]: value }
    }));
  };
  handleSubmit = () => {
    if (this.validateForm()) {
      this.setState({ errors: [] });
      this.props.createReservation({
        ...this.state.formInput,
        wishId: this.props.wish.id,
        uid: this.props.wish.uid
      });
    }
  };

  validateForm = () => {
    const errors = [];
    const { name, email, amount, street, city } = this.state.formInput;

    if (!name) {
      errors.push("Name darf nicht leer sein");
    }
    if (!email) {
      errors.push("Email darf nicht leer sein");
    } else if (!validateEmail(email)) {
      errors.push("Email ungültig");
    }
    if (!street) {
      errors.push("Strasse darf nicht leer sein");
    }
    if (!city) {
      errors.push("Ort darf nicht leer sein");
    }
    if (!amount && this.props.wish.type === WISH_TYPES.FLEXIBLE_SHARES) {
      errors.push("Betrag darf nicht leer sein");
    }
    this.setState({ errors });
    return errors.length <= 0;
  };

  handleAmountChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      formInput: {
        ...prevState.formInput,
        [name]:
          value <= this.props.wish.availableVal
            ? value
            : this.props.wish.availableVal
      }
    }));
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fetching && !this.props.fetching && !this.props.error) {
      this.setState({ success: true });
    }
  }

  render() {
    return (
      <>
        <h3>{this.props.wish.name}</h3>
        <WishInfoWrapper>
          <WishImageWrapper>
            <StyledImage src={this.props.wish.imageUrl || DEFAULT_IMAGE} />
          </WishImageWrapper>
          <WishInfo>{this.props.wish.description}</WishInfo>
        </WishInfoWrapper>
        <ReservationWrapper>
          <h5>Reserviere diesen Wunsch</h5>
          {this.state.success && (
            <NotificationBar success={true}>
              Vielen Dank für deine Reservation. <br />
              Du erhältst in Kürze ein Bestätigung an{" "}
              <b>{this.state.formInput.email}</b>. Bitte überprüfe auch deinen
              Junk-Ordner, falls du das E-Mail nicht im Posteingang findest.
            </NotificationBar>
          )}
          {!this.state.success && (
            <FormWrapper>
              {this.props.wish.type === WISH_TYPES.FLEXIBLE_SHARES ? (
                <InputFull>
                  <InputHalf>
                    <InputLabel>Betrag in CHF</InputLabel>
                    <TextInput
                      type="number"
                      name="amount"
                      value={this.state.formInput.amount}
                      onChange={this.handleAmountChange}
                      disableMargin
                    />
                  </InputHalf>
                </InputFull>
              ) : null}
              <InputHalf>
                <InputLabel>Name</InputLabel>
                <TextInput
                  type="text"
                  name="name"
                  value={this.state.formInput.name}
                  onChange={this.handleFormChange}
                  disableMargin
                />
              </InputHalf>
              <InputHalf right>
                <InputLabel>Email</InputLabel>
                <TextInput
                  type="text"
                  name="email"
                  value={this.state.formInput.email}
                  onChange={this.handleFormChange}
                  disableMargin
                />
              </InputHalf>
              <InputHalf>
                <InputLabel>Strasse, Nr</InputLabel>
                <TextInput
                  type="text"
                  name="street"
                  value={this.state.formInput.street}
                  onChange={this.handleFormChange}
                  disableMargin
                />
              </InputHalf>
              <InputHalf right>
                <InputLabel>PLZ, Ort</InputLabel>
                <TextInput
                  type="text"
                  name="city"
                  value={this.state.formInput.city}
                  onChange={this.handleFormChange}
                  disableMargin
                />
              </InputHalf>
              <InputFull>
                <InputLabel>Bemerkungen</InputLabel>
                <TextAreaInput
                  name="description"
                  value={this.state.formInput.description}
                  onChange={this.handleFormChange}
                  disableMargin
                />
              </InputFull>
            </FormWrapper>
          )}
          {(this.props.error || this.state.errors.length > 0) && (
            <NotificationBar>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color={"#fff"}
                style={{ marginRight: "1rem" }}
              />{" "}
              <b>Fehler</b>
              {this.props.error}
              {this.state.errors.length > 0 && (
                <ul>
                  {this.state.errors.map(error => (
                    <li>{error}</li>
                  ))}
                </ul>
              )}
            </NotificationBar>
          )}
          {!this.state.success && (
            <Button
              text="Reservieren"
              onClick={this.handleSubmit}
              loading={this.props.fetching}
              color={this.props.color}
            />
          )}
        </ReservationWrapper>
      </>
    );
  }
}

export default connect(
  state => ({
    fetching: state.wishes.creatingReservation,
    error: state.wishes.reservationError
  }),
  dispatch => ({
    createReservation: reservation => dispatch(createReservation(reservation))
  })
)(WishReservation);
