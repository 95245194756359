import React, { useState } from "react";
import { firebaseConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { useWindowSize } from "../Helpers/windowSizeHook";
import { Zoom } from "react-reveal";
import { COLOR_SCHEME } from "../Views/Colors";

const NavWrapper = styled.ul`
  z-index: 20;
  position: absolute;
  width: 100%;
  padding: 0;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  background: ${props =>
    props.transparent ? "transparent" : COLOR_SCHEME.primary};
  min-height: 80px;
`;
const NavLink = styled.li`
  color: white;
  font-weight: 500;
  margin: 30px 0 30px 0;
  padding: 0 30px 0 0;
  &:hover {
    cursor: pointer;
    font-weight: 600;
    transform: translateY(-2px);
  }
  transition: all ease-in-out 0.3s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled(Link)`
  margin-left: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  img:hover {
    cursor: pointer;
  }
  img {
    max-width: 200px;
  }
`;

const MenuOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  top: 60px;
  background: ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  opacity: 0.9;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const MobileMenuItem = styled.div`
  padding: 30px;
  color: white;
  font-size: 1.75rem;
  font-weight: 600;
  &:hover {
    transform: translateY(-2px);
    cursor: pointer;
  }
  transition: all ease-in-out 0.3s;
`;
const MainNavigation = props => {
  const { auth } = props;
  const [showMenu, setShowMenu] = useState(false);
  const windowSize = useWindowSize();

  return (
    <>
      <NavWrapper transparent={props.transparent}>
        <Container>
          <LogoWrapper to={"/"}>
            <img src={"/media/beschenkt-white.svg"} alt={"Beschenkt.ch"} />
          </LogoWrapper>
          {windowSize.width > 768 && isLoaded(auth) && (
            <Link to={"/about/faq"}>
              <NavLink style={{ marginRight: "40px" }}>FAQ</NavLink>
            </Link>
          )}
          {windowSize.width > 768 && isLoaded(auth) && !auth.uid ? (
            <Link to={"/auth/login"}>
              <NavLink>Login</NavLink>
            </Link>
          ) : windowSize.width > 768 && isLoaded(auth) ? (
            <Link to={"/dashboard"}>
              <NavLink style={{ marginRight: "40px" }}>Dashboard</NavLink>
            </Link>
          ) : null}
          {windowSize.width <= 768 ? (
            <button
              className={`hamburger hamburger--collapse ${
                showMenu ? "is-active" : ""
              }`}
              type="button"
              onClick={() => {
                if (!showMenu) {
                  props.setMenuState(windowSize.height);
                } else {
                  props.setMenuState(0);
                }
                setShowMenu(!showMenu);
              }}
            >
              <span
                className="hamburger-box"
                style={{ transform: "translateY(10px)" }}
              >
                <span
                  className="hamburger-inner"
                  style={{
                    backgroundColor: "white"
                  }}
                />
              </span>
            </button>
          ) : null}
        </Container>
      </NavWrapper>
      {showMenu && windowSize.width <= 768 ? (
        <MenuOverlay color={props.menuColor}>
          <Zoom>
            <Link to={"/auth/login"}>
              <MobileMenuItem>
                {isLoaded(auth) && auth.uid ? "Dashboard" : "Login"}
              </MobileMenuItem>
            </Link>
            <Link to={"/auth/register"}>
              <MobileMenuItem>Registrieren</MobileMenuItem>
            </Link>
            <Link to={"/about/faq"}>
              <MobileMenuItem>FAQ</MobileMenuItem>
            </Link>
            {/*<a href={"https://blog.beschenkt.ch"}>
              <MobileMenuItem>Blog</MobileMenuItem>
            </a>
            <Link to={"/about/impressum"}>
              <MobileMenuItem>Impressum</MobileMenuItem>
            </Link>*/}
          </Zoom>
        </MenuOverlay>
      ) : null}
    </>
  );
};

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  })),
  withRouter
)(MainNavigation);
