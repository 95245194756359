import React from "react";
import { connect } from "react-redux";
import WishOverviewEntry from "./WishOverviewEntry";
import { Loader } from "../../Views/Loader";
import Button from "../../Views/Button";
import { compose } from "redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

class WishOverview extends React.Component {
  render() {
    return (
      <div>
        <h2>Wünsche</h2>
        <Button
          width={200}
          text={"Wunsch hinzufügen"}
          onClick={() => this.props.navigate("ADD")}
        />
        {this.props.wishes && this.props.wishes.length > 0 ? (
          <table className="table table-responsive table-hover mt-3">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">Wunsch</th>
                <th scope="col">Preis</th>
                <th scope="col">Bemerkungen</th>
                <th scope="col">Aktionen</th>
              </tr>
            </thead>

            <tbody>
              {this.props.wishes.map(
                (wish, i) =>
                  !wish.deleted && <WishOverviewEntry wish={wish} key={i} />
              )}
            </tbody>
          </table>
        ) : !isLoaded ? (
          <Loader />
        ) : isEmpty ? (
          <div className={"mt-5"}>
            <b>Hier siehts ziemlich leer aus...</b> <br />
            Erfasse jetzt deine ersten Wünsche
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  firestoreConnect(props => [
    {
      collection: "wishes",
      where: [["uid", "==", props.uid]] //["deleted", ">", ""]]
    } // or `todos/${props.todoId}`
  ]),
  connect((state, props) => ({
    wishes: state.firestore.ordered.wishes
  }))
)(WishOverview);
