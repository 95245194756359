import React, { useState } from "react";
import { Loader } from "../../Views/Loader";
import Chat from "./Chat";
import * as firebase from "firebase";

const Support = props => {
  if (!props.thread) {
    const initializeChat = firebase
      .functions()
      .httpsCallable("initializeSlackChat");
    initializeChat().then(res => console.log(res));
  }
  return (
    <div>
      <h2>Support</h2>
      <p className={"mt-3"}>
        Fragen, Ideen oder Anregungen? Schreibe uns hier deine Nachricht und wir
        melden uns so schnell wie möglich bei dir :)
      </p>
      {props.thread ? (
        <Chat thread={props.thread} />
      ) : (
        <div>
          {" "}
          <Loader />{" "}
          <p style={{ textAlign: "center" }}>
            <b>
              Bitte warten. Der chat wird initialisiert. <br />
              Schreib uns auf{" "}
              <a href={"mailto:info@beschenkt.ch"}>info@beschenkt.ch</a> wenn
              der Chat nicht funktioniert.
            </b>
          </p>
        </div>
      )}
    </div>
  );
};

export default Support;
