import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGift,
  faShareAlt,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { COLOR_SCHEME } from "../Views/Colors";

const Title = styled.div`
  color: ${COLOR_SCHEME.primary};
  font-size: 1.75rem;
  font-family: "Candal", serif;
  margin-bottom: 2rem;
  padding: 0 1rem;
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.05);
  flex: 1;
  margin: 0 40px 40px 40px;
  @media screen and (max-width: 768px) {
    margin: 20px 5px;
  }
`;

const FeatureCardLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.05);
  flex: 1;
  margin: 0 40px 40px 40px;
  @media screen and (max-width: 768px) {
    margin: 20px 5px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 4rem;
  color: ${COLOR_SCHEME.primary};
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;
const CardTitle = styled.div`
  color: ${COLOR_SCHEME.primary};
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
const CardText = styled.div`
  color: ${COLOR_SCHEME.dark};
  text-align: center;
`;

const CardSectionWrapper = styled.div`
  z-index: 10;
  margin: 0 40px;
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
  }
  @media screen and (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FeaturesSection = () => {
  return (
    <Container>
      <Title>Deine Wunschliste in 3 Schritten</Title>
      <CardSectionWrapper>
        <FeatureCardLink to={"/auth/register"}>
          <Icon icon={faUserCircle} />
          <CardTitle>Konto erstellen</CardTitle>
          <CardText>
            Registriere dich kostenlos und ohne Risiko. Du benötigst lediglich
            eine gültige E-Mail Adresse und schon kann es losgehen!
          </CardText>
        </FeatureCardLink>
        <FeatureCard>
          <Icon icon={faGift} />
          <CardTitle>Wünsche erfassen</CardTitle>

          <CardText>
            Erfasse deine Wünsche unabhängig von Marken oder Stores. Lade ein
            eigenes Bild hoch, lege den Preis fest und benenne den Wunsch,
            fertig.
          </CardText>
        </FeatureCard>{" "}
        <FeatureCard>
          <Icon icon={faShareAlt} />
          <CardTitle>Mit Gästen teilen</CardTitle>

          <CardText>
            Teile deine Wunschliste mit Gästen und Freunden. Erstelle dazu
            deinen eigenen Link:{" "}
            <Link to={"/lena-max"}>beschenkt.ch/lena-max</Link> <br />
            Die Aktivierung der Wunschliste kostet <b>einmalig CHF 49.-</b>
          </CardText>
        </FeatureCard>
      </CardSectionWrapper>
    </Container>
  );
};

export default FeaturesSection;
