import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  );
};

export { Loader };

const LoaderBtn = ({ style }) => {
  return <div style={style} className="loader-btn" />;
};

export { LoaderBtn };
