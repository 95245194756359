export const COLOR_SCHEME = {
  primary: "#d0003f",
  primaryHover: "rgba(208, 0, 63, 1)",
  secondary: "#121b74",
  secondaryHover: "rgba(97, 74, 211, 1)",
  lightBg: "#e0e0e0",
  light: "#9a9a9a",
  white: "#ffffff",
  success: "#0f850e",
  dark: "#343a40",
  danger: "#dc3545"
};
