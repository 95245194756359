import React, { useEffect, useState } from "react";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import * as firebase from "firebase";
import Hero from "../../Views/Hero";
import styled from "styled-components";
import Button from "../../Views/Button";
import { ErrorHandling } from "../../Helpers/ErrorHandling";
import { COLOR_SCHEME } from "../../Views/Colors";
import MainNavigation from "../../Navigation/MainNav";
import {
  ImageWrapper,
  LoginWrapper,
  StyledImage,
  TextInput,
  TextLink,
  Wrapper
} from "../SignUp/SignUp";
import { NoScroll } from "../../Helpers/NoScroll";
import { StyledLink } from "../../Views/Link";

export const Description = styled.div`
  max-width: 350px;
  @media (max-width: 768px) {
    max-width: 300px;
    text-align: center;
  }
`;

const ForgotPasswordComponent = props => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendResetMail = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("resetPassword");
  const resetPassword = () => {
    setLoading(true);
    sendResetMail({ email })
      .then(() => {
        setMessage(
          "Email erfolgreich gesendet. Du wirst gleich weitergeleitet."
        );
        setEmail("");
        setLoading(false);
        setTimeout(() => props.history.push("/auth/login"), 4000);
      })
      .catch(err => {
        setLoading(false);
        setError(ErrorHandling(err.code));
      });
  };
  const [menuHeight, setMenuHeight] = useState(0);

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Hero title={"Passwort vergessen"} />
      <Wrapper>
        <ImageWrapper>
          <StyledImage
            src={"/media/forgot-password.svg"}
            alt="forgot-password"
          />
        </ImageWrapper>
        <LoginWrapper>
          {error ? (
            <p
              style={{
                padding: "5px 10px",
                background: COLOR_SCHEME.primaryHover,
                color: "#fff"
              }}
            >
              {error}
            </p>
          ) : null}
          {message ? (
            <p
              style={{
                padding: "5px 10px",
                background: COLOR_SCHEME.success,
                color: "#fff"
              }}
            >
              {message}
            </p>
          ) : null}
          <Description>
            Bitte gib hier deine E-Mail Adresse ein, um dein Passwort
            zurückzusetzen.
          </Description>
          <TextInput
            id="username_input"
            placeholder="Email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            margin="normal"
          />
          <Button
            text={"Passwort zurücksetzen"}
            onClick={resetPassword}
            loading={loading}
            width={350}
            disabled={!email}
          />
          <TextLink>
            <StyledLink to={"/auth/login"}> Zurück zum Login</StyledLink>
          </TextLink>
        </LoginWrapper>
      </Wrapper>
    </NoScroll>
  );
};

export default compose(
  withRouter,
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(ForgotPasswordComponent);
