import React, { useEffect, useState } from "react";
import MainNavigation from "../../Navigation/MainNav";
import Hero from "../../Views/Hero";
import { NoScroll } from "../../Helpers/NoScroll";
import {
  ImageWrapper,
  LoginWrapper,
  StyledImage,
  TextInput,
  TextLink,
  Wrapper
} from "../SignUp/SignUp";
import { COLOR_SCHEME } from "../../Views/Colors";
import Button from "../../Views/Button";
import { StyledLink } from "../../Views/Link";
import { Description } from "../ForgotPassword/ForgotPassword";
import { withRouter } from "react-router-dom";
import * as firebase from "firebase";
import { Loader } from "../../Views/Loader";
import styled from "styled-components";
import ToastService from "../../Helpers/Toast";

const queryString = require("query-string");

const LoaderWrapper = styled.div`
  max-width: 300px;
  min-width: 200px;
`;
const ResetPassword = props => {
  const [menuHeight, setMenuHeight] = useState(0);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [actionCode, setActionCode] = useState("");
  const [mode, setMode] = useState("");
  const [restoredEmail, setRestoredEmail] = useState("");
  const [restoredEmailSuccess, setRestoredEmailSuccess] = useState(false);

  const resetPassword = () => {
    console.log("should reset now");
    setLoading(true);
    firebase
      .auth()
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        firebase.auth().signInWithEmailAndPassword(userEmail, password);
        setLoading(false);
        ToastService.raiseSuccess("Passwort erfolgreich geändert.");
        setTimeout(() => props.history.push("/dashboard"), 1000);
      })
      .catch(() => {
        setLoading(false);
        setError(
          "Ups, etwas hat nicht funktioniert. Bitte fordere einen neuen Reset-Link an."
        );
        setTimeout(() => props.history.push("/auth/forgot-password"), 5000);
      });
  };

  useEffect(() => {
    const { mode } = queryString.parse(props.location.search);
    setMode(mode);
    // check if code is valid
    const { oobCode } = queryString.parse(props.location.search);
    if (oobCode && mode === "resetPassword") {
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(email => {
          setUserEmail(email);
          setActionCode(oobCode);
        })
        .catch(() => props.history.push("/auth/login"));
    } else if (oobCode && mode === "recoverEmail") {
      firebase
        .auth()
        .checkActionCode(oobCode)
        .then(info => {
          setRestoredEmail(info["data"]["email"]);
          return firebase.auth().applyActionCode(oobCode);
        })
        .then(() => {
          setRestoredEmailSuccess(true);
        })
        .catch(e => ToastService.raiseWarn("Code ungültig oder abgelaufen."));
    } else {
      props.history.push("/auth/login");
    }
  }, []);

  if (mode === "recoverEmail") {
    return (
      <NoScroll>
        <MainNavigation setMenuState={height => setMenuHeight(height)} />
        <Hero title={"Email Änderung Rückgängig machen"} />
        <Wrapper>
          <ImageWrapper>
            <StyledImage
              src={"/media/reset-password.svg"}
              alt="forgot-password"
            />
          </ImageWrapper>
          <LoginWrapper>
            {restoredEmailSuccess && (
              <>
                Deine Email wurde zurückgesetzt auf <b>{restoredEmail}</b>
              </>
            )}
            <TextLink>
              <StyledLink to={"/auth/login"}> Zurück zum Login</StyledLink>
            </TextLink>
          </LoginWrapper>
        </Wrapper>
      </NoScroll>
    );
  }

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Hero title={"Passwort zurücksetzen"} />
      <Wrapper>
        <ImageWrapper>
          <StyledImage
            src={"/media/reset-password.svg"}
            alt="forgot-password"
          />
        </ImageWrapper>
        <LoginWrapper>
          {error ? (
            <p
              style={{
                padding: "5px 10px",
                background: COLOR_SCHEME.primaryHover,
                color: "#fff"
              }}
            >
              {error}
            </p>
          ) : null}
          {userEmail ? (
            <>
              <Description>
                Gib hier das neue Passwort für den Account <b>{userEmail}</b>{" "}
                ein
              </Description>
              <TextInput
                id="newPassword"
                placeholder=""
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                type={"password"}
              />
              <Button
                text={"Passwort zurücksetzen"}
                onClick={resetPassword}
                loading={loading}
                width={350}
                disabled={password.length < 6}
              />
              <TextLink>
                <StyledLink to={"/auth/login"}> Zurück zum Login</StyledLink>
              </TextLink>
            </>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </LoginWrapper>
      </Wrapper>
    </NoScroll>
  );
};

export default withRouter(ResetPassword);
