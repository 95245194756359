import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min";
import MainRouter from "./Routes/MainRouter";
import { Helmet } from "react-helmet";

require("dotenv").config();

function App() {
  return (
    <>
      <Helmet>
        <title>
          Beschenkt.ch – Lass dich beschenken und beschenke dabei andere!
        </title>
        <meta
          name="description"
          content="Erstelle deine Wunschliste unabhängig von Marken oder Stores und teile sie mit deinen Gästen. Einfach, schnell und modern - Deine Wunschliste bei Beschenkt.ch"
        />
        <meta httpEquiv="language" content="DE" />
      </Helmet>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer />
          {/*
        <MainNavigation />
*/}
          <MainRouter />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
