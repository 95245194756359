import React from "react";

const EmailPreview = props => {
  return (
    <>
      <table
        border="0"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        bgcolor={props.color}
      >
        <tr>
          <td
            align="center"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat"
            }}
          >
            <table
              border="0"
              align="center"
              width="590"
              cellPadding="0"
              cellSpacing="0"
              className="container590"
            >
              <tr>
                <td
                  height="25"
                  style={{ fontSize: "25px", lineHeight: " 25px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td>
                  <table
                    border="0"
                    align="left"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td align="center">
                        <table
                          border="0"
                          cellPadding="0"
                          cellSpacing="0"
                          align="center"
                        >
                          <tr>
                            <td align="center">
                              <a
                                href="https://beschenkt.ch"
                                style={{
                                  display: "block",
                                  borderStyle: "none !important",
                                  border: "0 !important"
                                }}
                              >
                                <img
                                  width="200"
                                  border="0"
                                  style={{ display: "block", width: "200px" }}
                                  src="http://img.mailinblue.com/2412594/images/5ddac3ecae16d_1574618092.png"
                                  alt=""
                                />
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>

                  <table
                    border="0"
                    align="left"
                    width="5"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td
                        width="5"
                        height="10"
                        style={{ fontSize: "10px", lineHeight: "10px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>

                  <table
                    border="0"
                    align="right"
                    cellPadding="0"
                    width="360"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse", marginTop: "25px" }}
                    className="hide"
                  >
                    <tr>
                      <td
                        align="right"
                        className="outlook-font"
                        style={{
                          color: "#ddefff",
                          fontSize: "14px",
                          fontFamily:
                            "'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                          lineHeight: "24px"
                        }}
                      >
                        <div style={{ lineHeight: "24px" }} />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="hide">
                <td
                  height="35"
                  style={{ fontSize: "35px", lineHeight: "35px" }}
                >
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td
                  height="50"
                  style={{ fontSize: "50px", lineHeight: "50px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td align="center">
                  <table
                    border="0"
                    width="500"
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td
                        align="center"
                        style={{
                          color: "#ffffff",
                          fontSize: "35px",
                          fontFamily:
                            "'Titillium Web', Helvetica Neue, Calibri, sans-serif;",
                          lineHeight: "35px"
                        }}
                        className="main-section-header outlook-font"
                      >
                        <div style={{ lineHeight: "35px" }}>
                          Deine Reservierung f&uuml;r {props.wishlistName}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        height="20"
                        style={{ fontSize: "20px", lineHeight: "20px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td
                  height="40"
                  style={{ fontSize: "40px", lineHeight: "40px" }}
                >
                  &nbsp;
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table
        border="0"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="ffffff"
      >
        <tr>
          <td height="55" style={{ fontSize: "55px", lineHeight: "55px" }}>
            {" "}
            &nbsp;
          </td>
        </tr>

        <tr>
          <td align="center">
            <table
              border="0"
              align="center"
              width="590"
              cellPadding="0"
              cellSpacing="0"
              className="container590"
            >
              <tr>
                <td
                  align="center"
                  style={{
                    color: "#343434",
                    fontSize: "30px",
                    fontFamily:
                      "'Source Sans Pro', Helvetica, Calibri, sans-serif",
                    fontWeight: "500",
                    lineHeight: "32px"
                  }}
                  className="outlook-font header-main"
                >
                  Dein reservierter Wunsch
                </td>
              </tr>

              <tr>
                <td
                  height="20"
                  style={{ fontSize: "20px", lineHeight: "20px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td align="center">
                  <table
                    align="center"
                    width="70"
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    bgcolor={props.color}
                  >
                    <tr>
                      <td
                        height="1"
                        style={{ fontSize: "1px", lineHeight: "1px" }}
                      />
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td
                  height="60"
                  style={{ fontSize: "60px", lineHeight: "60px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td>
                  <table
                    border="0"
                    width="180"
                    align="left"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td align="center">
                        <img
                          src={props.imageUrl}
                          style={{ display: "block", width: "140px" }}
                          width="140"
                          border="0"
                          alt=""
                        />
                      </td>
                    </tr>
                  </table>

                  <table
                    border="0"
                    width="5"
                    align="left"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td
                        width="5"
                        height="30"
                        style={{ fontSize: "30px", lineHeight: "30px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                  </table>

                  <table
                    border="0"
                    width="380"
                    align="right"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td
                        align="left"
                        style={{
                          color: "#373a41",
                          fontSize: "20px",
                          fontFamily:
                            "'Source Sans Pro', Helvetica, Calibri, sans-serif",
                          fontWeight: "500",
                          lineHeight: "24px"
                        }}
                        className="align-center outlook-font"
                      >
                        {props.wishName}
                      </td>
                    </tr>

                    <tr>
                      <td
                        height="15"
                        style={{ fontSize: "15px", lineHeight: "15px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        style={{
                          color: "#7d7d7d",
                          fontSize: "15px",
                          fontFamily:
                            "'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                          lineHeight: "24px"
                        }}
                        className="align-center outlook-font"
                      >
                        {props.wishDescription}
                      </td>
                    </tr>
                    <tr>
                      <td
                        height="15"
                        style={{ fontSize: "15px", lineHeight: "15px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        style={{
                          color: "#7d7d7d",
                          fontSize: "15px",
                          fontFamily:
                            " 'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                          lineHeight: "24px"
                        }}
                        className="align-center outlook-font"
                      >
                        Reservierter Betrag: <b>{props.wishAmount}</b>{" "}
                        {props.wishlistCurrency}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td align="center" style={{ paddingTop: "30px" }}>
            <table
              border="0"
              align="center"
              width="590"
              cellPadding="0"
              cellSpacing="0"
              className="container590"
            >
              <tr>
                <td
                  align="center"
                  style={{
                    color: "#343434",
                    fontSize: "30px",
                    fontFamily:
                      "'Source Sans Pro', Helvetica, Calibri, sans-serif",
                    fontWeight: "500",
                    lineHeight: "32px"
                  }}
                  className="outlook-font header-main"
                >
                  {props.wishlistPersonalTitle
                    ? props.wishlistPersonalTitle
                    : "Infos von " + props.wishlistName}
                </td>
              </tr>

              <tr>
                <td
                  height="20"
                  style={{ fontSize: "20px", lineHeight: "20px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td align="center">
                  <table
                    align="center"
                    width="70"
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    bgcolor={props.color}
                  >
                    <tr>
                      <td
                        height="1"
                        style={{ fontSize: "1px", lineHeight: "1px" }}
                      />
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  height="60"
                  style={{ fontSize: "60px", lineHeight: "60px" }}
                >
                  &nbsp;
                </td>
              </tr>

              <tr>
                <td>
                  <table
                    border="0"
                    width="480"
                    align="right"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ borderCollapse: "collapse" }}
                    className="container590"
                  >
                    <tr>
                      <td
                        align="left"
                        style={{
                          color: "#373a41",
                          fontSize: "20px",
                          fontFamily:
                            "'Source Sans Pro', Helvetica, Calibri, sans-serif",
                          fontWeight: "500",
                          lineHeight: "24px"
                        }}
                        className="align-center outlook-font"
                      >
                        {props.wishlistInfo}
                      </td>
                    </tr>

                    <tr>
                      <td
                        height="15"
                        style={{ fontSize: "15px", lineHeight: "15px" }}
                      >
                        &nbsp;
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        style={{
                          color: " #7d7d7d",
                          fontSize: "15px",
                          fontFamily:
                            "'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                          lineHeight: "24px"
                        }}
                        className="align-center outlook-font"
                      >
                        {props.wishlistPayment}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td height="60" style={{ fontSize: "60px", lineHeight: "60px" }}>
            &nbsp;
          </td>
        </tr>
      </table>

      <table
        border="0"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="f8f9fa"
      >
        <tr>
          <td height="25" style={{ fontSize: "25px", lineHeight: "25px;" }}>
            &nbsp;
          </td>
        </tr>

        <tr>
          <td align="center">
            <table
              border="0"
              align="center"
              width="590"
              cellPadding="0"
              cellSpacing="0"
              className="container590"
            >
              <tr>
                <td
                  align="center"
                  style={{
                    color: "#9c9c9c",
                    fontSize: "13px",
                    fontFamily:
                      "'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                    lineHeight: "24px;"
                  }}
                  className="outlook-font align-center"
                >
                  Beschenkt.ch
                </td>
              </tr>
              <tr>
                <td
                  height="30"
                  style={{ fontSize: "30px", lineHeight: "30px" }}
                >
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    color: "#9c9c9c",
                    fontSize: "13px",
                    fontFamily:
                      "'Titillium Web', Helvetica Neue, Calibri, sans-serif",
                    lineHeight: "24px"
                  }}
                  className="outlook-font align-center"
                >
                  Du erh&auml;ltst diese Nachricht weil du mit der E-Mail{" "}
                  <a href="UnsubscribeURL" style={{ color: "#9c9c9c" }}>
                    {props.userEmail}{" "}
                  </a>{" "}
                  einen Wunsch auf{" "}
                  <a href="https://beschenkt.ch" style={{ color: "#9c9c9c" }}>
                    Beschenkt.ch
                  </a>{" "}
                  reserviert hast.
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td height="25" style={{ fontSize: "25px", lineHeight: "25px" }}>
            &nbsp;
          </td>
        </tr>
      </table>
    </>
  );
};

export default EmailPreview;
