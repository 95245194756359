import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import MainNavigation from "../Navigation/MainNav";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import FeaturesSection from "./FeaturesSection";
import CallToActionSection from "./CallToActionSection";
import SocialSection from "./SocialSection";
import Footer from "./Footer";
import { useWindowSize } from "../Helpers/windowSizeHook";
import { NoScroll } from "../Helpers/NoScroll";
import { COLOR_SCHEME } from "../Views/Colors";
const Wrapper = styled.div`
  position: relative;
`;
const Hero = styled.div`
  z-index: 1;
  background: ${COLOR_SCHEME.primary};
  width: 100%;
  height: 500px;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 68%, 0% 100%);
  @media (max-width: 425px) {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    margin-bottom: 50px;
  }
`;

const HeroContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 2rem;
  position: relative;
`;

const MainTitle = styled.h1`
  font-size: 2.5rem;
  font-family: "Candal", serif;
  text-align: center;
  color: white;
  font-weight: 300;
  margin: 100px 0 10px 0;
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;
const SubTitle = styled.div`
  font-size: 1.5em;
  color: white;
  font-weight: 200;
  margin-bottom: 40px;
  @media (max-width: 425px) {
    font-size: 1.3rem;
    text-align: center;
  }
`;

const CallToAction = styled(Link)`
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: 1px solid white;
  background: ${props => (props.secondary ? COLOR_SCHEME.primary : "#ffffff")};
  border-radius: 2px;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => (props.secondary ? "#ffffff" : COLOR_SCHEME.primary)};
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    color: ${props =>
      props.secondary ? "#ffffff" : COLOR_SCHEME.primary}!important;
  }
  transition: all ease-in-out 0.1s;
`;

const FaIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;
const CardSectionWrapper = styled.div`
  z-index: 10;
  margin: 0 40px;
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
  }
  @media screen and (min-width: 993px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Macbook = styled.div`
  z-index: 10;
  position: absolute;
  max-width: 450px;
  object-fit: contain;
  top: 40%;
  left: 65%;
  @media (max-width: 1024px) {
    top: 50%;
  }
  @media (max-width: 860px) {
    display: none;
  }
`;

const DeviceWrapper = styled.img`
  width: 100%;
  height: auto;
  z-index: 10;
`;

const Video = styled.video`
  height: auto;
  position: absolute;
  left: 10%;
  width: 79%;
  top: 7%;
  z-index: -1;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const HomeComponent = ({ history }) => {
  const redirectToLogin = () => {
    history.push("/auth/register");
  };
  const [menuHeight, setMenuHeight] = useState(0);
  const { height, width } = useWindowSize();

  useEffect(() => {
    window.setTimeout(() => (window.prerenderReady = true), 5000);
  }, []);

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Wrapper>
        <Hero>
          <HeroContainer>
            <Fade left>
              <MainTitle>Hochzeit, Geburtstag oder Geburt?</MainTitle>
              <SubTitle>
                Erstelle jetzt deine Wunschliste und lass dich beschenken!
              </SubTitle>
            </Fade>
            <Fade bottom>
              <ButtonWrapper>
                <CallToAction to={"/auth/register"}>
                  Los geht's <FaIcon icon={faGift} />
                </CallToAction>
                <CallToAction to={"/lena-max"} secondary={true}>
                  Demo
                </CallToAction>
              </ButtonWrapper>
            </Fade>
          </HeroContainer>
        </Hero>
        {width > 860 && (
          <Macbook>
            <Video
              className={"video"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/beschenkt-react.appspot.com/o/static%2Fbeschenkt-demo.mp4?alt=media&token=aba7d3df-32fb-4dd2-a200-adf8fb8d3e10"
              }
              autoPlay={true}
              loop={true}
            />
            <DeviceWrapper
              className={"device-wrapper"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/beschenkt-react.appspot.com/o/static%2Fmacbook-mock-beschenkt.png?alt=media&token=575fcaf2-4d44-49ec-a084-9f50e2d93b4f"
              }
              alt={"macbook-mock"}
            />
          </Macbook>
        )}
      </Wrapper>
      <FeaturesSection />
      <CallToActionSection />
      <SocialSection />
      {/*<DemoSection />*/}
      <Footer />
    </NoScroll>
  );
};

export default withRouter(HomeComponent);
