import React, { useState } from "react";
import MainNavigation from "../components/Navigation/MainNav";
import Hero from "../components/Views/Hero";
import styled from "styled-components";
import Button from "../components/Views/Button";
import { useWindowSize } from "../components/Helpers/windowSizeHook";
import { NoScroll } from "../components/Helpers/NoScroll";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const NotFound = () => {
  const [menuHeight, setMenuHeight] = useState(0);
  const { height, width } = useWindowSize();

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Hero title={"Whoops! Hier gehts nicht weiter"} />
      <Wrapper>
        <a href={"/"}>
          <Button text={"Zurück"} onClick={() => null} />
        </a>
      </Wrapper>
    </NoScroll>
  );
};

export default NotFound;
