import React, { useState } from "react";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
import { getAlphaColor } from "./getAlphaColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const TextInput = styled.input`
  width: ${props => (props.disableMargin ? "100%" : "calc(100% - 30px)")};
  border-radius: 0.125rem;
  padding: 15px;
  margin: ${props => (props.disableMargin ? "0" : "5px 10px 10px 0")};
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
`;
export const TextAreaInput = styled.textarea`
  width: ${props => (props.disableMargin ? "100%" : "calc(100% - 30px)")};
  border-radius: 0.125rem;
  padding: 15px;
  margin: ${props => (props.disableMargin ? "0" : "5px 10px 10px 0")};
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
`;

export const InputLabel = styled.div`
  font-size: 0.875rem;
  color: ${COLOR_SCHEME.light};
`;

const StyledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background: ${props =>
    props.checked ? COLOR_SCHEME.primary : COLOR_SCHEME.lightBg};
  &:hover {
    background: ${props =>
      props.checked
        ? getAlphaColor(COLOR_SCHEME.primary, 0.7)
        : getAlphaColor(COLOR_SCHEME.lightBg, 0.7)};
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CheckboxLabel = styled.p`
  margin: 0;
`;

export const Checkbox = ({ label, children, onChange, defaultValue }) => {
  const [checked, check] = useState(defaultValue);
  const handleCheck = () => {
    onChange(!checked);
    check(!checked);
  };
  return (
    <CheckboxContainer>
      <StyledCheckbox checked={checked} onClick={handleCheck}>
        {checked && <FontAwesomeIcon icon={faCheck} />}
      </StyledCheckbox>
      {children}
      {!children && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxContainer>
  );
};
