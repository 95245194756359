export const WISH_TYPES = {
  NON_MONETARY: "NonMonetary",
  FLEXIBLE_SHARES: "FlexibleShares"
};

export const WISH_TYPES_LABELS = {
  NonMonetary: "Sachgeschenk",
  FlexibleShares: "Flexible Anteile"
};

export const DEFAULT_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/beschenkt-react.appspot.com/o/static%2Fdefault-wish-image.jpg?alt=media&token=fe1cd28d-7b31-4830-9f8b-0e496818afb9";
