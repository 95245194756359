import React from "react";
import gift from "../../assets/animations/beschenkt-gift";
import LottieManager from "../Helpers/LottieManager";
import { Fade, Zoom } from "react-reveal";
import RubberBand from "react-reveal";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
const Container = styled.div`
  max-width: 1400px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: ${COLOR_SCHEME.primary};
  font-size: 1.75rem;
  font-family: "Candal", serif;
  margin-bottom: 2rem;
  padding: 0 1rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Text = styled.div`
  color: ${COLOR_SCHEME.dark};
  text-align: center;
`;

const SocialSection = () => {
  return (
    <Container>
      <RubberBand>
        <LottieManager
          animationData={gift}
          isPaused={true}
          height={275}
          width={275}
          loop={false}
        />
      </RubberBand>
      <Zoom>
        <Title>Lass dich beschenken und beschenke dabei andere</Title>
      </Zoom>
      <Fade right>
        <Text>
          Nicht alle Leute haben Grund zu feiern. <br /> Deshalb gehen pro
          aktivierter Wunschliste CHF 5.- an soziale Hilfswerke um auch andere
          Leute zu beschenken. <br />
          <br />
          Somit hast du gleich doppelt Grund, um zu feiern. Let's party!
        </Text>
      </Fade>
    </Container>
  );
};

export default SocialSection;
