import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestore } from "../../../redux/store";
import styled from "styled-components";
import { COLOR_SCHEME } from "../../Views/Colors";
import { ContentWrapper } from "../DashboardComponent";
import Button from "../../Views/Button";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { InputLabel } from "../../Helpers/FormComponents";
import ToastService from "../../Helpers/Toast";
import { Payment } from "../../Payment/PaymentIntent";
import { Loader } from "../../Views/Loader";
import * as _ from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const QRCode = require("qrcode.react");

const TextInput = styled.input`
  border-radius: 0.125rem;
  padding: 10px;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  width: 100%;
`;

const AvailabilityIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 1rem;
`;

const TextInputWrapper = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 500px;
  position: relative;
  margin: 0 10px 0 0;
`;

export const Wrapper = styled.div`
  flex: 1;
`;

const ErrorBanner = styled.div`
  margin: 0;
  color: white;
  padding: 10px 20px;
  background: ${COLOR_SCHEME.primaryHover};
  width: 100%;
`;

const ListCodeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
  flex-wrap: wrap;
`;

const SuccessMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SuccessMessageTitle = styled.p`
  font-weight: bold;
  margin: 1rem;
  font-size: 1.2rem;
`;

const StyledFAIcon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
  transition: all ease-in-out 0.2s;
`;

const CodeError = styled.div`
  position: absolute;
  background: ${COLOR_SCHEME.primary};
  color: #ffffff;
  padding: 5px 10px;
`;
const ActivateWishlist = props => {
  const [stripeReady, setStripeReady] = useState(false);
  const [regexValid, setRegexValid] = useState(true);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.id = "stripejs";
    script.async = true;
    script.onload = () => {
      setStripeReady(true);
    };
    document.body.appendChild(script);
    return () => {
      const stripeScript = document.getElementById("stripejs");
      if (stripeScript) {
        stripeScript.remove();
      }
    };
  }, []);
  const [listCode, setListCode] = useState("");
  const [checkingCode, setCheckingCode] = useState(true);
  const [listCodeAvailable, setListCodeAvailable] = useState(false);
  const [request, setRequest] = useState({ error: "", updating: false });
  const [qrColor, setQrColor] = useState("#000000");
  const [paymentClientSuccess, setPaymentClientSuccess] = useState(false);

  const checkListCodeAvailability = code => {
    if (code.length < 3) {
      setListCodeAvailable(false);
    } else {
      setCheckingCode(true);
      firestore
        .collection("wishlistRouting")
        .where("listCode", "==", code)
        .get()
        .then(querySnapshot => {
          setListCodeAvailable(querySnapshot.docs.length <= 0);
          setCheckingCode(false);
        })
        .catch(() => {
          setListCodeAvailable(false);
          setCheckingCode(false);
        });
    }
  };

  const checkAvailabilityDebounce = useRef(
    _.debounce(code => checkListCodeAvailability(code), 500)
  ).current;

  const changeListCode = () => {
    setRequest({ ...request, updating: true });

    firestore
      .collection("wishlistRouting")
      .doc(props.uid)
      .update({ listCode })
      .then(() => {
        ToastService.raiseSuccess("Zugangscode aktualisiert");
        setRequest({ error: "", updating: false });
      });
  };
  const CodeRegex = RegExp(/^[a-z0-9&_-]+$/i);
  const user = props.user ? props.user[0] : {};
  const routeDoc = props.wishlistRouting
    ? props.wishlistRouting[Object.keys(props.wishlistRouting)[0]]
    : {};
  return (
    <Wrapper>
      {isLoaded(props.user) && !user.activated ? (
        <ErrorBanner>Deine Wunschliste wurde noch nicht aktiviert.</ErrorBanner>
      ) : null}

      {isLoaded(props.wishlistRouting) && routeDoc.deleted ? (
        <ErrorBanner>
          Deine Wunschliste wurde vom Administrator deaktiviert und ist deshalb
          nicht mehr aufrufbar. Bitte wende dich an den Support um die
          Wunschliste wieder aufzuschalten.
        </ErrorBanner>
      ) : null}

      {isLoaded(props.user) ? (
        <ContentWrapper>
          <h2>Einstellungen</h2>
          <p>
            Deine Wunschliste ist momentan unter{" "}
            <a
              href={`/${routeDoc.listCode}`}
            >{`${window.location.hostname}/${routeDoc.listCode}`}</a>{" "}
            verfügbar. Um den Sobald deine Wunschliste aktiviert ist, kannst du
            den Link zur Wunschliste personalisieren.
          </p>

          <InputLabel>
            {user.activated
              ? `Zugangscode für Wunschliste (https://beschenkt.ch/${listCode ||
                  routeDoc.listCode})`
              : "Deine Wunschliste ist momentan noch nicht aktiviert, du kannst den Zugangscode deshalb nicht ändern."}
          </InputLabel>
          {user.activated && (
            <>
              <ListCodeWrapper>
                <TextInputWrapper>
                  <TextInput
                    type="text"
                    name="listCode"
                    disabled={!user.activated}
                    value={listCode ? listCode : ""}
                    defaultValue={routeDoc.listCode}
                    onChange={e => {
                      setListCode(e.target.value.toLowerCase());
                      checkAvailabilityDebounce(e.target.value.toLowerCase());
                      setRegexValid(CodeRegex.test(e.target.value));
                    }}
                  />
                  <AvailabilityIconWrapper>
                    {listCodeAvailable ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={COLOR_SCHEME.success}
                      />
                    ) : (
                      <StyledFAIcon
                        icon={faTimes}
                        color={COLOR_SCHEME.primary}
                        onClick={() => {
                          setListCode("");
                        }}
                      />
                    )}
                  </AvailabilityIconWrapper>
                  {!regexValid && (
                    <CodeError>
                      Erlaubt sind Buchstaben, Zahlen und die Zeichen & - _
                    </CodeError>
                  )}
                  {!listCodeAvailable &&
                    listCode.length >= 3 &&
                    !checkingCode && (
                      <CodeError>
                        Dieser Zugangscode ist bereits in Verwendung :(
                      </CodeError>
                    )}
                </TextInputWrapper>

                <Button
                  text="Zugangscode Ändern"
                  onClick={e => changeListCode(e)}
                  loading={request.updating}
                  width={200}
                  disabled={!listCodeAvailable || !regexValid}
                />
              </ListCodeWrapper>
              <QRCode
                value={`${window.location.hostname}/${routeDoc.listCode}`}
                renderAs="canvas"
                size={128}
                fgColor={qrColor}
              />
            </>
          )}

          {/*  <TwitterPicker
              onChange={color => this.setState({ qrColor: color.hex })}
            />*/}
          {!user.activated && stripeReady && (
            <>
              <Payment showSuccess={() => setPaymentClientSuccess(true)} />
            </>
          )}
          {!user.activated && paymentClientSuccess && (
            <SuccessMessageWrapper>
              <Loader />
              <SuccessMessageTitle>Zahlung eingegangen 🎉</SuccessMessageTitle>
              <p>
                Bitte habe einen Moment Geduld währenddem wir deine Wunschliste
                aktivieren.
              </p>
            </SuccessMessageWrapper>
          )}
        </ContentWrapper>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default compose(
  connect(store => ({
    uid: store.firebase.auth.uid,
    user: store.firestore.ordered.users,
    wishlistRouting: store.firestore.data.wishlistRouting,
    status: store.firestore.status.requesting
  })),
  firestoreConnect(props => [
    { collection: "users", doc: props.uid },
    { collection: "wishlistRouting", where: ["uid", "==", props.uid] }
  ])
)(ActivateWishlist);
