import React, { useEffect, useRef, useState } from "react";
import { Loader } from "../../Views/Loader";
import Button from "../../Views/Button";
import styled from "styled-components";
import { getAlphaColor } from "../../Helpers/getAlphaColor";
import { COLOR_SCHEME } from "../../Views/Colors";
import { firestore } from "../../../redux/store";
import { useCollectionData } from "react-firebase-hooks/firestore";
const ChatWrapper = styled.div`
  max-width: 1000px;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  min-height: 300px;
  max-height: 500px;
  @media (max-width: 465px) {
    max-height: 350px;
  }
`;

const MessageSupport = styled.div`
  background: ${getAlphaColor(COLOR_SCHEME.primary, 0.8)};
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  color: #ffffff;
  max-width: 70%;
  margin-right: auto;
`;

const MessageClient = styled.div`
  background: ${COLOR_SCHEME.lightBg};
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  max-width: 70%;
  margin-left: auto;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 15px;
  margin: 5px 10px 10px 0;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
`;

const InputWrapper = styled.div`
  max-width: 1000px;
`;

const NoMessage = styled.div`
  color: ${COLOR_SCHEME.light};
  text-align: center;
  margin-top: 2rem;
`;
const Chat = props => {
  const [input, setInput] = useState("");
  const ChatRef = useRef(null);
  const sendMessage = () => {
    firestore
      .collection("slackChat")
      .add({
        message: input,
        time: Math.floor(Date.now() / 1000),
        thread: props.thread,
        user: true
      })
      .then(() => setInput(""))
      .catch(err => console.log(err));
  };

  const chatRef = firestore
    .collection("slackChat")
    .where("thread", "==", props.thread)
    .orderBy("time", "asc");
  const [snapshot, loading, error] = useCollectionData(chatRef);

  useEffect(() => {
    ChatRef.current.scrollTop = ChatRef.current.scrollHeight;
  }, [snapshot]);
  return (
    <>
      <ChatWrapper ref={ChatRef}>
        {loading ? (
          <Loader />
        ) : snapshot && snapshot.length > 0 ? (
          snapshot.map((m, i) =>
            m.user ? (
              <MessageClient key={i}>
                {m.message.split("\n").map((p, i) => (
                  <p key={i} style={{ marginBottom: "0" }}>
                    {p}
                  </p>
                ))}
              </MessageClient>
            ) : (
              <MessageSupport key={i}>{m.message}</MessageSupport>
            )
          )
        ) : (
          <NoMessage>Noch keine Nachrichten vorhanden</NoMessage>
        )}
      </ChatWrapper>
      <InputWrapper>
        <StyledTextarea
          placeholder={"Deine Nachricht"}
          value={input}
          onChange={e => setInput(e.target.value)}
        />
        <Button text={"Senden"} onClick={sendMessage} width={300} />
      </InputWrapper>
    </>
  );
};

export default Chat;
