import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLOR_SCHEME } from "./Colors";

export const StyledLink = styled(Link)`
  color: ${COLOR_SCHEME.primary};
  text-decoration: none;
  &:hover {
    color: ${COLOR_SCHEME.primaryHover};
  }
`;
