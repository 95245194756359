const initState = {
  loading: false,
  fetching: false,
  creatingReservation: false,
  reservationError: "",
  error: "",
  wishlist: []
};

export const wishReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_WISH_PROCESSING":
      return { ...state, loading: true };
    case "CREATE_WISH_SUCCEED":
      return { ...state, loading: false };
    case "CREATE_WISH_ERROR":
      return { ...state, loading: false, error: action.error };
    case "EDIT_WISH_PROCESSING":
      return { ...state, loading: true };
    case "EDIT_WISH_SUCCEED":
      return { ...state, loading: false };
    case "EDIT_WISH_ERROR":
      return { ...state, loading: false, error: action.error };
    case "CREATE_RESERVATION_PROCESSING":
      return { ...state, creatingReservation: true };
    case "CREATE_RESERVATION_SUCCEED":
      return { ...state, creatingReservation: false, reservationError: "" };
    case "CREATE_RESERVATION_ERROR":
      return {
        ...state,
        creatingReservation: false,
        reservationError: action.err
      };
    default:
      return state;
  }
};
