import React, { useState } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import SingleWish from "./SingleWish";
import { firestoreConnect } from "react-redux-firebase";
import WishReservation from "./WishReservation";
import Modal from "../Modals/Modal";
import FlipMove from "react-flip-move";
import { getAlphaColor } from "../Helpers/getAlphaColor";
import { COLOR_SCHEME } from "../Views/Colors";

const WishWrapper = styled(FlipMove)`
  max-width: 1400px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 3rem;
  grid-auto-rows: minmax(100px, auto);
  padding: 0 15px;
  @media (max-width: 425px) {
    margin: 5px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 788px) {
    justify-items: center;
  }
`;
const CategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  max-width: 1400px;
  margin: auto;
  flex-wrap: wrap;
`;

const Category = styled.div`
  border-radius: 5px;
  padding: 8px 15px;
  border: 1px solid
    ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  color: ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  margin: 1rem;
  background-color: ${props =>
    props.active
      ? props.color
        ? getAlphaColor(props.color, 0.1)
        : getAlphaColor(COLOR_SCHEME.primary, 0.1)
      : "#ffffff"};
  &:hover {
    background-color: ${props =>
      props.color
        ? getAlphaColor(props.color, 0.1)
        : getAlphaColor(COLOR_SCHEME.primary, 0.1)};
    cursor: pointer;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const WishlistComponent = props => {
  const [showModal, setShowModal] = useState(false);
  const [wishToReserve, setWishToReserve] = useState("");
  const [category, selectCategory] = useState("alle");

  const getWishAttribute = (id, attr) => {
    const wish = props.wishes.find(wish => wish.id === id);
    if (wish) {
      return wish[attr] ? wish[attr] : "";
    }
  };

  return (
    <>
      {showModal ? (
        <Modal
          clickOutsideHandler={() => setShowModal(false)}
          title={getWishAttribute(wishToReserve, "availableVal") + " CHF"}
          color={props.color}
        >
          <WishReservation
            wish={
              props.wishes.find(wish => wish.id === wishToReserve)
                ? props.wishes.find(wish => wish.id === wishToReserve)
                : {}
            }
            color={props.color}
          />
        </Modal>
      ) : null}
      {props.categories && (
        <CategoryWrapper>
          {["Alle", ...props.categories].map((cat, i) => (
            <Category
              color={props.color}
              onClick={() => selectCategory(cat.toLowerCase())}
              active={category === cat.toLowerCase()}
              key={i}
            >
              {cat}
            </Category>
          ))}
        </CategoryWrapper>
      )}
      <WishWrapper>
        {props.wishes && props.wishes.length > 0
          ? props.wishes
              .filter(wish => wish.category === category || category === "alle")
              .map(
                wish =>
                  !wish.deleted && (
                    <CardWrapper key={wish.id}>
                      <SingleWish
                        color={props.color}
                        wish={wish}
                        showModal={() => setShowModal(true)}
                        setWishToReserve={id => setWishToReserve(id)}
                      />
                    </CardWrapper>
                  )
              )
          : null}
      </WishWrapper>
    </>
  );
};

export default compose(
  firestoreConnect(props => [
    { collection: "wishes", where: ["uid", "==", props.uid] }
  ]),
  connect(state => ({
    wishes: state.firestore.ordered.wishes
  }))
)(WishlistComponent);
