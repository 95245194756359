import React, { Component } from "react";
import styled from "styled-components";
import Button from "../../Views/Button";
import { COLOR_SCHEME } from "../../Views/Colors";
import { connect } from "react-redux";
import { firestore } from "../../../redux/store";
import ToastService from "../../Helpers/Toast";
import { TwitterPicker } from "react-color";
import EmailPreview from "./EmailPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp
} from "@fortawesome/free-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import {
  DeleteIcon,
  Dropper,
  ImageWrapper,
  Progressbar,
  ProgressbarWrapper,
  StyledDropzone
} from "../CreateWish/CreateWish";
import * as firebase from "firebase";
import { DEFAULT_IMAGE } from "../../Helpers/constants";

const ColorBar = styled.div`
  width: 276px;
  height: 30px;
  margin-bottom: 20px;
  background: ${props => props.color};
`;

const TextAreaInput = styled.textarea`
  width: 100%;
  border-radius: 0.125rem;
  padding: 15px;
  margin: 5px 10px 10px 0;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
`;

const TextInput = styled.input`
  width: 100%;
  border-radius: 0.125rem;
  padding: 15px;
  margin: 5px 10px 10px 0;
  &:focus {
    outline: none;
  }
  border: none;
  background-color: rgba(100, 100, 100, 0.1);
  font-size: 1rem;
  line-height: 1.3;
  min-width: 200px;
`;

const InputLabel = styled.div`
  font-size: 0.875rem;
  color: ${COLOR_SCHEME.light};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const EmailPreviewWrapper = styled.div`
  max-width: 700px;
  box-shadow: 3px 2px 10px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
`;

class Personalize extends Component {
  state = {
    color: "",
    title: "",
    bankDetails: "",
    message: "",
    messageNonMonetary: "",
    personalTitle: "",
    image: "",
    loading: false,
    error: "",
    wishlistDescription: "",
    showEmailPreview: false,
    uploading: false,
    progress: 0
  };
  componentDidMount() {
    firestore
      .collection("wishlistSettings")
      .doc(this.props.uid)
      .get()
      .then(doc => {
        this.setState({ ...doc.data() });
      });
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleFileChange = file => {
    this.setState({ uploading: true });
    const storage = firebase.storage();
    const uploadRef = storage.ref().child(`uploads/${Date.now()}-${file.name}`);
    const uploadTask = uploadRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ progress });
      },
      error => {
        // Handle unsuccessful uploads
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.setState(prevState => ({
            image: downloadURL,
            uploading: false,
            progress: 0
          }));
        });
      }
    );
  };
  removeImage = () => {
    const storage = firebase.storage();
    const ref = storage.refFromURL(this.state.image);
    ref
      .delete()
      .then(() => {
        console.log("delete successful!");
        this.setState(prevState => ({ image: "" }));
        this.onSubmit();
      })
      .catch(err => {
        if (err.code === "storage/object-not-found") {
          this.setState(prevState => ({
            wish: ""
          }));
        }
      });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const p1 = firestore
      .collection("wishlistRouting")
      .doc(this.props.uid)
      .update({
        title: this.state.title,
        color: this.state.color,
        image: this.state.image,
        description: this.state.wishlistDescription
      });
    const p2 = firestore
      .collection("wishlistSettings")
      .doc(this.props.uid)
      .update({
        message: this.state.message,
        bankDetails: this.state.bankDetails,
        color: this.state.color,
        title: this.state.title,
        personalTitle: this.state.personalTitle,
        messageNonMonetary: this.state.messageNonMonetary,
        image: this.state.image,
        wishlistDescription: this.state.wishlistDescription
      });
    Promise.all([p1, p2])
      .then(() => {
        this.setState({ loading: false });
        ToastService.raiseSuccess("Einstellungen aktualisiert.");
      })
      .catch(console.log);
  };
  render() {
    return (
      <>
        <h2 className={"mb-5"}>Personalisieren</h2>
        <h5>Wunschliste</h5>
        <InputLabel>Titel der Wunschliste</InputLabel>
        <TextInput
          value={this.state.title}
          onChange={this.handleInputChange}
          name="title"
          placeholder={"Hochzeit Lisa & Max"}
        />
        {this.state.image ? (
          <ImageWrapper style={{ marginBottom: "1rem" }}>
            <img className={"img-fluid"} src={this.state.image} />
            <DeleteIcon onClick={this.removeImage}>X</DeleteIcon>
          </ImageWrapper>
        ) : (
          <StyledDropzone
            onDrop={acceptedFiles => this.handleFileChange(acceptedFiles[0])}
          >
            {({ getRootProps, getInputProps }) => (
              <Dropper {...getRootProps()}>
                <input {...getInputProps()} />
                <FontAwesomeIcon icon={faCloudUploadAlt} />
                <div>Bild hierhin ziehen oder klicken um auszuwählen</div>
              </Dropper>
            )}
          </StyledDropzone>
        )}
        {this.state.uploading ? (
          this.state.progress < 100 ? (
            <ProgressbarWrapper>
              <Progressbar progress={this.state.progress} />
            </ProgressbarWrapper>
          ) : (
            <p>Verarbeite...</p>
          )
        ) : null}
        <InputLabel>
          Erfasse hier eine kleine Beschreibung die im Titel deiner Wunschliste
          angezeigt wird.
        </InputLabel>
        <TextAreaInput
          value={this.state.wishlistDescription}
          onChange={this.handleInputChange}
          name="wishlistDescription"
          rows={3}
          placeholder={
            "Wir heiraten im Sommer! Hier findet ihr ein paar Wünsche von uns, vielen Dank wenn du uns einen davon erfüllst! :)"
          }
        />
        <InputLabel style={{ marginBottom: "1rem" }}>
          Wähle die Akzentfarbe deiner Wunschliste.
        </InputLabel>
        <ColorBar color={this.state.color || COLOR_SCHEME.primary} />
        <TwitterPicker
          onChange={({ hex }) => this.setState({ color: hex })}
          colors={[
            "#FF6900",
            "#FCB900",
            "#7BDCB5",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            COLOR_SCHEME.primary,
            "#F78DA7",
            "#9900EF",
            COLOR_SCHEME.secondary
          ]}
        />
        <Button
          width={300}
          onClick={this.onSubmit}
          text={"Einstellungen speichern"}
          loading={this.state.loading}
        />
        <hr />
        <h5>E-Mail an Schenkende</h5>
        <InputLabel>Persönliche Überschrift (optional)</InputLabel>
        <TextInput
          value={this.state.personalTitle}
          onChange={this.handleInputChange}
          name="personalTitle"
          placeholder={"Infos von Lisa & Max"}
        />
        <InputLabel>
          Erfasse hier eine persönliche Nachricht, die jede(r) Schenkende per
          E-Mail erhält.
        </InputLabel>
        <TextAreaInput
          value={this.state.message}
          onChange={this.handleInputChange}
          name="message"
          rows={3}
          placeholder={
            "Vielen Dank für dein Geschenk, wir freuen uns sehr auf das Fest mit dir!"
          }
        />
        <InputLabel>
          Persönliche Nachricht für Sachgeschenke (nur falls abweichend)
        </InputLabel>
        <TextAreaInput
          value={this.state.messageNonMonetary}
          onChange={this.handleInputChange}
          name="messageNonMonetary"
          rows={3}
          placeholder={
            "Vielen Dank für dein Geschenk. Wir freuen uns, wenn du das Geschenk gleich zu unserem Fest bringst."
          }
        />
        <InputLabel>
          Erfasse deine Bankverbindung, so dass Schenkende den Betrag direkt an
          dein Konto überweisen können.
        </InputLabel>
        <TextAreaInput
          value={this.state.bankDetails}
          onChange={this.handleInputChange}
          name="bankDetails"
          rows={4}
          placeholder={
            "IBAN: CH00 0000 0000 0000 0000 0 \nMax Muster \nMusterstrasse 1 \n1234 Musterhausen"
          }
        />
        <ButtonWrapper>
          <Button
            width={300}
            onClick={this.onSubmit}
            text={"Einstellungen speichern"}
            loading={this.state.loading}
            style={{ marginRight: "1rem" }}
          />
          <Button
            width={300}
            color={COLOR_SCHEME.lightBg}
            style={{ color: "#555" }}
            onClick={() => {
              this.setState(
                prevState => ({
                  showEmailPreview: !prevState.showEmailPreview
                }),
                () =>
                  window.scrollTo({
                    top: 1200,
                    behavior: "smooth"
                  })
              );
            }}
          >
            E-Mail Vorschau{" "}
            {this.state.showEmailPreview ? "verbergen" : "anzeigen"}
            <FontAwesomeIcon
              icon={
                this.state.showEmailPreview
                  ? faChevronCircleUp
                  : faChevronCircleDown
              }
              style={{ marginLeft: "1rem" }}
            />
          </Button>
        </ButtonWrapper>
        {this.state.showEmailPreview && (
          <EmailPreviewWrapper>
            <EmailPreview
              userEmail={"test@test.ch"}
              wishName={"Kaffeemaschine"}
              wishDescription={"Damit wir am Morgen aus den Federn kommen :)"}
              wishAmount={200}
              wishlistName={this.state.title}
              wishlistInfo={this.state.message}
              wishlistPayment={this.state.bankDetails}
              wishlistPersonalTitle={this.state.personalTitle}
              wishlistCurrency={"CHF"}
              imageUrl={DEFAULT_IMAGE}
              color={this.state.color || COLOR_SCHEME.primary}
            />
          </EmailPreviewWrapper>
        )}
      </>
    );
  }
}

export default connect(state => ({ uid: state.firebase.auth.uid }))(
  Personalize
);
