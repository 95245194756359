import React, { useState } from "react";
import { Text } from "./Welcome";
import { TextAreaInput, TextInput } from "../../../Helpers/FormComponents";
import styled from "styled-components";
import TutorialFooter from "../TutorialFooter";
import { firestore } from "../../../../redux/store";

export const InputWrapper = styled.div`
  width: 100%;
  margin: 1rem 0 2rem 0;
  @media (min-width: 768px) {
    width: 90%;
    margin: 1rem auto 2rem auto;
  }
`;

const TutorialWishlistInformation = ({ uid, next }) => {
  const [personalMessage, setPersonalMessage] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [showLoading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    firestore
      .collection("wishlistSettings")
      .doc(uid)
      .update({ message: personalMessage, bankDetails })
      .then(() => {
        setLoading(false);
        next();
      })
      .catch(console.log);
  };

  return (
    <>
      <h2 style={{ marginBottom: "1rem" }}>Informationen für Schenkende</h2>
      <Text>
        Sobald jemand einen Wunsch reserviert, erhält er eine Bestätigung per
        E-Mail.
        <br />
        Hier kannst du die Nachricht mit einem eigenen Text personalisieren:
      </Text>
      <InputWrapper>
        <TextAreaInput
          rows={4}
          disableMargin
          placeholder={
            "Vielen Dank für dein Geschenk. Wir freuen uns sehr auf das Fest mit dir!"
          }
          value={personalMessage}
          onChange={e => setPersonalMessage(e.target.value)}
        />
      </InputWrapper>
      <Text>
        Hier kannst du deine Bankverbindung angeben, <br /> damit Schenkende dir
        ganz einfach das Geld überweisen können.
      </Text>
      <InputWrapper>
        <TextAreaInput
          rows={3}
          disableMargin
          placeholder={
            "IBAN: CH00 0000 0000 0000 0 \nMax Mustermann \n1234 Musterhausen"
          }
          value={bankDetails}
          onChange={e => setBankDetails(e.target.value)}
        />
      </InputWrapper>
      <TutorialFooter onClick={handleNext} loading={showLoading} />
    </>
  );
};

export default TutorialWishlistInformation;
