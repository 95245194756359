const uuid = require("uuid/v4");

export const createWish = wish => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "CREATE_WISH_PROCESSING", wish });
    const firestore = getFirestore();
    const id = uuid();
    const state = getState();
    firestore
      .collection("wishes")
      .doc(id)
      .set({
        ...wish,
        id,
        createdAt: new Date(),
        uid: state.firebase.auth.uid,
        availableVal: +wish.price,
        price: +wish.price,
        reservedVal: 0,
        numberOfShares: +wish.numberOfShares
      })
      .then(() => {
        dispatch({ type: "CREATE_WISH_SUCCEED", wish });
      })
      .catch(err => {
        dispatch({ type: "CREATE_WISH_ERROR", err });
      });
  };
};

export const editWish = wish => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "EDIT_WISH_PROCESSING", wish });
    const firestore = getFirestore();
    const id = wish.id;
    const state = getState();
    firestore
      .collection("wishes")
      .doc(id)
      .update({
        ...wish
      })
      .then(() => {
        dispatch({ type: "EDIT_WISH_SUCCEED", wish });
      })
      .catch(err => {
        dispatch({ type: "EDIT_WISH_ERROR", err });
      });
  };
};

export const createReservation = reservation => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "CREATE_RESERVATION_PROCESSING", reservation });
    const firestore = getFirestore();
    firestore
      .collection("wishes")
      .doc(reservation.wishId)
      .collection("reservations")
      .add({
        ...reservation,
        createdAt: new Date(),
        amount: +reservation.amount
      })
      .then(() => dispatch({ type: "CREATE_RESERVATION_SUCCEED" }))
      .catch(err => {
        dispatch({ type: "CREATE_RESERVATION_ERROR", err });
      });
  };
};
