import React, { useState } from "react";
import { Text } from "./Welcome";
import styled from "styled-components";
import { TextInput } from "../../../Helpers/FormComponents";
import TutorialFooter from "../TutorialFooter";
import { firestore } from "../../../../redux/store";

const RadioWrapper = styled.div`
  margin: 2rem 0;
  position: relative;
  left: 40%;
`;

export const InputWrapper = styled.div`
  @media (min-width: 768px) {
    width: 75%;
    margin: auto;
  }
`;

const TutorialPersonalDetails = ({ next, uid }) => {
  const radioSelection = [
    { label: "Hochzeit", value: "wedding" },
    { label: "Geburtstag", value: "birthday" },
    { label: "Geburt", value: "birth" },
    { label: "Anderes", value: "other" }
  ];
  const placeholders = [
    "z.B. Hochzeit von Max und Lisa",
    "z.B. 18. Geburtstag von Tim",
    "z.B Geburt von Lian",
    ""
  ];
  const [checkedRadio, setCheckedRadio] = useState("");
  const [wishlistTitle, setWishlistTitle] = useState("");
  const [showLoading, setLoading] = useState(false);
  const isPlural = () => {
    return checkedRadio === "wedding";
  };

  const handleNext = () => {
    setLoading(true);
    const p1 = firestore
      .collection("wishlistRouting")
      .doc(uid)
      .update({ title: wishlistTitle });
    const p2 = firestore
      .collection("wishlistSettings")
      .doc(uid)
      .update({ occasion: checkedRadio, title: wishlistTitle });
    Promise.all([p1, p2])
      .then(() => {
        setLoading(false);
        next();
      })
      .catch(console.log);
  };
  return (
    <>
      <h2>
        {isPlural()
          ? "Erzählt uns etwas über euch"
          : "Erzähl uns etwas über dich"}
      </h2>
      <Text>
        {isPlural()
          ? "Um eure Wunschliste optimal einzurichten, benötigen wir ein paar Informationen von euch."
          : "Um deine Wunschliste optimal einzurichten, benötigen wir ein paar Informationen von dir"}
      </Text>
      <h4 style={{ marginTop: "2rem" }}>
        Was gibt es zu feiern?
        <span role={"img"} aria-label={"Party Emoji"}>
          🎉
        </span>
      </h4>
      <RadioWrapper>
        {radioSelection.map((radio, i) => (
          <div className="custom-control custom-radio text-left" key={i}>
            <input
              type="radio"
              id={"customRadio" + i}
              name="customRadio"
              className="custom-control-input"
              value={radio.value}
              checked={radio.value === checkedRadio}
              onChange={e => setCheckedRadio(e.target.value)}
            />
            <label className="custom-control-label" htmlFor={"customRadio" + i}>
              {radio.label}
            </label>
          </div>
        ))}
      </RadioWrapper>
      <h4>Titel der Wunschliste</h4>
      <Text style={{ marginBottom: "1rem" }}>
        {isPlural()
          ? "Ihr könnt den Titel eurer"
          : "Du kannst den Titel deiner"}{" "}
        Wunschliste später immer noch anpassen
      </Text>
      <InputWrapper>
        <TextInput
          disableMargin
          placeholder={placeholders[checkedRadio]}
          value={wishlistTitle}
          onChange={e => setWishlistTitle(e.target.value)}
        />
      </InputWrapper>
      <TutorialFooter onClick={handleNext} loading={showLoading} />
    </>
  );
};

export default TutorialPersonalDetails;
