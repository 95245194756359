import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import Button from "../../Views/Button";
import { Link } from "react-router-dom";
import { InputLabel, TextInput } from "../../Helpers/FormComponents";
import ToastService from "../../Helpers/Toast";
import styled from "styled-components";
import * as firebase from "firebase";

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-bottom: 20px;
`;

const UserAccount = ({ auth: user }) => {
  const fbUser = firebase.auth().currentUser;

  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwRequired, setPwRequired] = useState(false);

  const updateEmail = useCallback(() => {
    setLoading(true);
    fbUser
      .updateEmail(newEmail)
      .then(() => {
        setPwRequired(false);
        setNewEmail("");
        setPassword("");
        ToastService.raiseSuccess("Email aktualisiert!");
      })
      .catch(e => {
        if (e.code === "auth/requires-recent-login") {
          setPwRequired(true);
          ToastService.raiseWarn(
            "Bitte bestätige diese Aktion mit deinem Passwort"
          );
          return;
        }
        ToastService.raiseError(
          "Etwas ist schief gelaufen. Bitte versuche es erneut oder kontaktiere den Support"
        );
      })
      .finally(() => setLoading(false));
  }, [newEmail]);

  const verify = useCallback(() => {
    setLoading(true);
    const credential = firebase.auth.EmailAuthProvider.credential(
      fbUser.email,
      password
    );
    fbUser
      .reauthenticateWithCredential(credential)
      .then(updateEmail)
      .then(() => console.log("email updated"))
      .catch(e => {
        console.log(e);
        ToastService.raiseError(
          "Etwas ist schief gelaufen. Bitte versuche es erneut oder kontaktiere den Support"
        );
      })
      .finally(() => setLoading(false));
  }, [password]);

  return (
    <div>
      <h2>Mein Konto</h2>
      <p>
        Du bist momentan als <b>{fbUser.email}</b> angemeldet
      </p>
      <h5>Email</h5>
      <p>Hier kannst du deine E-Mail Addresse ändern</p>
      <InputLabel>Neue E-Mail</InputLabel>
      <TextInput value={newEmail} onChange={e => setNewEmail(e.target.value)} />
      {pwRequired && (
        <>
          <InputLabel>Passwort</InputLabel>
          <TextInput
            type={"password"}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </>
      )}
      <ButtonWrapper>
        <Button onClick={pwRequired ? verify : updateEmail} loading={loading}>
          {pwRequired ? "Bestätigen" : "E-Mail aktualisieren"}
        </Button>
      </ButtonWrapper>
      <h5>Passwort</h5>
      <p>
        Wenn du dein Passwort ändern möchtest, kannst du es zurücksetzen und ein
        neues eingeben.
      </p>
      <Link to={"/auth/forgot-password"}>
        <Button style={{ maxWidth: "250px" }}>Passwort zurücksetzen</Button>
      </Link>
    </div>
  );
};

export default connect((state, props) => ({
  auth: state.firebase.auth
}))(UserAccount);
