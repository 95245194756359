import React, { useState } from "react";
import Footer from "./Footer";
import Hero from "../Views/Hero";
import MainNavigation from "../Navigation/MainNav";
import styled from "styled-components";
import withScrollToTop from "../Helpers/ScrollToTop";
import { NoScroll } from "../Helpers/NoScroll";

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

const Subtitle = styled.h5`
  margin: 2rem 0 1rem 0;
`;

const Block = styled.p`
  text-align: justify;
`;
const Impressum = () => {
  const [menuHeight, setMenuHeight] = useState(0);

  return (
    <NoScroll height={menuHeight}>
      <MainNavigation setMenuState={height => setMenuHeight(height)} />
      <Hero title={"Impressum"} />
      <Container>
        <Subtitle>Kontaktadresse</Subtitle>
        Beschenkt.ch <br />
        Jonas Brunner <br />
        Titlisweg 1 <br />
        5040 Schöftland (CH) <br />
        <a href={"mailto:info@beschenkt.ch"}>info@beschenkt.ch</a>
        <Subtitle>Haftungsausschluss</Subtitle>
        <Block>
          Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
          Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
          Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor
          wegen Schäden materieller oder immaterieller Art, welche aus dem
          Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten
          Informationen, durch Missbrauch der Verbindung oder durch technische
          Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind
          unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
          Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
          verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
          oder endgültig einzustellen.
        </Block>
        <Subtitle>Haftung für Links</Subtitle>{" "}
        <Block>
          Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
          Verantwortungsbereichs Es wird jegliche Verantwortung für solche
          Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
          erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
        </Block>
        <Subtitle>Urheberrechte</Subtitle>{" "}
        <Block>
          Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
          anderen Dateien auf der Website gehören ausschliesslich Beschenkt.ch
          oder den speziell genannten Rechtsinhabern. Für die Reproduktion
          jeglicher Elemente ist die schriftliche Zustimmung der
          Urheberrechtsträger im Voraus einzuholen.
        </Block>
        <Subtitle>Datenschutz</Subtitle>{" "}
        <Block>
          Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die
          datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz,
          DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
          Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese
          Bestimmungen ein. Persönliche Daten werden streng vertraulich
          behandelt und weder an Dritte verkauft noch weiter gegeben. In enger
          Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die
          Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten,
          Missbrauch oder vor Fälschung zu schützen. Beim Zugriff auf unsere
          Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse,
          Datum, Uhrzeit, Browser-Anfrage und allg. übertragene Informationen
          zum Betriebssystem resp. Browser. Diese Nutzungsdaten bilden die Basis
          für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,
          anhand derer wir unsere Angebote entsprechend verbessern können.
        </Block>
        <Subtitle>
          Datenschutzerklärung für die Nutzung von Google Analytics
        </Subtitle>{" "}
        <Block>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies",
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
          auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Google
          wird diese Informationen benutzen, um Ihre Nutzung der Website
          auszuwerten, um Reports über die Websiteaktivitäten für die
          Websitebetreiber zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu
          erbringen. Auch wird Google diese Informationen gegebenenfalls an
          Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
          Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen
          von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengeführt. Sie können die
          Installation der Cookies durch eine entsprechende Einstellung Ihrer
          Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Website voll umfänglich nutzen können. Durch die Nutzung dieser
          Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen
          Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem
          zuvor benannten Zweck einverstanden.
        </Block>
      </Container>
      <Footer />
    </NoScroll>
  );
};

export default withScrollToTop(Impressum);
