import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import * as firebase from "firebase";
import { firestore } from "../redux/store";
import { useDocument } from "react-firebase-hooks/firestore";

export function useAuthenticatedUserDetails() {
  const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());
  const userRef = firestore.collection("users").doc(user.uid);
  const [snapshot, loading, error] = useDocument(userRef);
  return [
    snapshot && snapshot.exists ? snapshot.data() : false,
    loading || loadingAuth || !snapshot.exists,
    errorAuth ? errorAuth : error
  ];
}
