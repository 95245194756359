import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { COLOR_SCHEME } from "../Views/Colors";
const ClipPath = styled.div`
  background: ${COLOR_SCHEME.primary};
  width: 100%;
  height: 300px;
  clip-path: polygon(0 0, 100% 25%, 100% 75%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const CtaText = styled.div`
  font-size: 2rem;
  color: white;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CtaBtn = styled.div`
  border-radius: 2px;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 50px;
  padding: 15px;
  border: 1px solid white;
  background: white;
  color: ${COLOR_SCHEME.primary};
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  transition: all ease-in-out 0.1s;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-left: 10px;
  }
`;

const CallToActionSection = ({ history }) => {
  return (
    <ClipPath>
      <Container>
        <CtaText>Deine Wunschliste ist nur 1 Klick entfernt</CtaText>
        <CtaBtn onClick={() => history.push("auth/register")}>
          Jetzt registrieren
        </CtaBtn>
      </Container>
    </ClipPath>
  );
};

export default withRouter(CallToActionSection);
