import React, { useState } from "react";
import withScrollToTop from "../Helpers/ScrollToTop";
import MainNavigation from "../Navigation/MainNav";
import Hero from "../Views/Hero";
import Footer from "./Footer";
import { Container } from "./Impressum";
import FAQItem from "./FAQItem";
import { NoScroll } from "../Helpers/NoScroll";
import { Helmet } from "react-helmet";

const FAQ = () => {
  const [menuHeight, setMenuHeight] = useState(0);

  return (
    <>
      <Helmet>
        <title>Beschenkt.ch – Häufig gestellte Fragen (FAQ)</title>
        <meta
          name="description"
          content="Was passiert, wenn jemand einen Wunsch reserviert? Finde Antworten auf die am häufigsten gestellten Fragen (FAQ) bei Beschenkt.ch"
        />
      </Helmet>
      <NoScroll height={menuHeight}>
        <MainNavigation setMenuState={height => setMenuHeight(height)} />
        <Hero title={"Häufig gestellte Fragen (FAQ)"} />
        <Container>
          <h3>Wunschliste</h3>
          <FAQItem title={"Kann ich die Wunschliste zuerst testen?"}>
            Ja! Du kannst jederzeit kostenlos ein Konto erstellen und die
            Wunschliste testen. Um ein Konto zu erstellen benötigst du lediglich
            eine gültige E-Mail Adresse.
          </FAQItem>
          <FAQItem title={"Muss ich die Wunschliste aktivieren?"}>
            Nein, du musst die Wunschliste nicht unbedingt aktivieren. Mit der
            Aktivierung schaltest du allerdings die Möglichkeit frei, den
            Zugangslink zu deiner Wunschliste anzupassen. (z.B.{" "}
            <a href={"#"}>beschenkt.ch/meinLink</a>)
          </FAQItem>
          <FAQItem title={"Was passiert wenn jemand einen Wunsch reserviert?"}>
            Wenn jemand einen Wunsch reserviert, erhält er von uns ein
            Bestätigungsmail mit allen Details zum reservierten Wunsch. Dieses
            E-Mail kannst du in deinem persönlichen Konto mit einem eigenen Text
            personalisieren. Für Geldgeschenke kannst du ausserdem deine
            Kontoangaben im E-Mail einfügen, so dass die Gäste das Geld direkt
            zu dir überweisen können.
          </FAQItem>
          <FAQItem
            title={"Wie erfahre ich, ob jemand einen Wunsch reserviert hat?"}
          >
            In deinem persönlichen Konto kannst du jederzeit sehen, wer welchen
            Wunsch reserviert hat. Bei einer Reservierung müssen Gäste auch ihre
            Postadresse angeben. Einem schönen Dankeskärtchen steht also nichts
            im Weg, oder? :)
          </FAQItem>
          <FAQItem
            title={
              "Ich wünsche mir Geldgeschenke. Wie kommt das Geld der Gäste zu mir?"
            }
          >
            Beschenkt.ch nimmt kein Geld von Gästen an. Die Gäste überweisen das
            Geld direkt an dich. Du kannst in deinem persönlichen Benutzerkonto
            deine Bankverbindung angeben. Deine Bankverbindung wird dann im
            Bestätigungsmail an deine Gäste versendet.
          </FAQItem>
          <FAQItem
            title={
              "Ich finde eine Funktion nicht / Ich habe einen Vorschlag für eine Funktion"
            }
          >
            Wir sind immer offen für neue Ideen. Schick uns unbedingt die
            Funktion, die du vermisst an{" "}
            <a href={"mailto:info@beschenkt.ch"}>info@beschenkt.ch</a> und
            vielleicht wird sie schon bald umgesetzt :)
          </FAQItem>
          <h3>Zahlung</h3>
          <FAQItem title={"Welche Zahlungsmittel kann ich nutzen?"}>
            Wir unterstützen momentan nur die Zahlung per <b>Kreditkarte</b>. So
            kann deine Wunschliste direkt nach Zahlungseingang aktiviert werden.
            Deine Kreditkartendaten sind sicher und werden zu keiner Zeit auf
            unseren Servern gespeichert.
          </FAQItem>
          <FAQItem
            title={
              "Ich habe keine Kreditkarte, kann ich meine Wunschliste trotzdem aktivieren?"
            }
          >
            Solltest du keine Kreditkarte haben, können wir deine Wunschliste
            nicht automatisiert aktivieren. Nimm am Besten mit uns unter{" "}
            <a href={"mailto:info@beschenkt.ch"}>info@beschenkt.ch</a> kontakt
            auf, dann finden wir bestimmt eine Lösung :)
          </FAQItem>
        </Container>
        <Footer />
      </NoScroll>
    </>
  );
};

export default withScrollToTop(FAQ);
