import React, { useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../redux/store";
import { Text } from "./Welcome";
import TutorialFooter from "../TutorialFooter";
import Lottie from "react-lottie";
import * as firebase from "firebase";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: require("../../../../assets/animations/check.json")
};

const TutorialWishlistRouting = ({ uid, next }) => {
  const path = firestore.collection("wishlistRouting").doc(uid);
  const [routingDoc, loading, error] = useDocumentDataOnce(path);
  const [showLoading, setLoading] = useState(false);

  const tutorialComplete = firebase
    .functions()
    .httpsCallable("tutorialComplete");

  const handleNext = () => {
    setLoading(true);
    tutorialComplete()
      .then(() => {
        setLoading(false);
        next();
      })
      .catch(console.log);
  };
  return (
    <>
      <h2>Deine Wunschliste ist ready!</h2>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isPaused={false}
        isStopped={false}
      />
      <Text>
        Du findest deine Wunschliste unter{" "}
        <a
          href={
            loading
              ? ""
              : `https://${window.location.hostname}/${routingDoc.listCode}`
          }
          target={"_blank"}
        >
          {window.location.hostname}/{!loading && routingDoc.listCode}
        </a>
        <br />
        Alle Funktionen sind ab sofort für dich freigeschaltet. Um die URL
        deiner Wunschliste zu ändern,
        <br /> musst du die Wunschliste einmalig aktivieren.
      </Text>
      <TutorialFooter onClick={handleNext} isLast loading={showLoading} />
    </>
  );
};

export default TutorialWishlistRouting;
