import React, { Component } from "react";
import WishlistComponent from "./WishlistComponent";
import MainNavigation from "../Navigation/MainNav";
import { NoScroll } from "../Helpers/NoScroll";
import WishlistHero from "./WishlistHero";

class WishlistView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      wishToReserve: {},
      menuHeight: 0
    };
  }

  render() {
    return (
      <NoScroll height={this.state.menuHeight}>
        <MainNavigation
          setMenuState={height => this.setState({ menuHeight: height })}
          transparent={true}
          onlyAuthenticated={true}
          menuColor={this.props.color}
        />
        <WishlistHero
          title={this.props.wishlistTitle}
          color={this.props.color}
          image={this.props.image}
          description={this.props.description}
        />

        <WishlistComponent
          setWishToReserve={wish =>
            this.setState({ wishToReserve: { ...wish } })
          }
          uid={this.props.uid}
          showModal={() => this.setState({ showModal: true })}
          color={this.props.color}
          categories={this.props.categories}
        />
      </NoScroll>
    );
  }
}

export default WishlistView;
