import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DEFAULT_IMAGE, WISH_TYPES_LABELS } from "../../Helpers/constants";

const ImageWrapper = styled.div`
  max-width: 100px;
  min-width: 75px;
  height: auto;
  img {
    object-fit: contain;
  }
`;

const Description = styled.td`
  max-width: 300px;
`;
const WishReservationEntry = props => {
  return (
    <tr>
      <td>
        <ImageWrapper>
          <img
            src={props.reservation.imageUrl || DEFAULT_IMAGE}
            className={"img-fluid"}
          />
        </ImageWrapper>
      </td>
      <td>{props.reservation.wishName}</td>
      <td>
        {props.reservation.name}
        <br />
        {props.reservation.street}
        <br />
        {props.reservation.city}
        <br />
        <a href={"mailto:" + props.reservation.email}>
          {props.reservation.email}
        </a>
      </td>
      <td>
        <b>
          {props.reservation.amount
            ? props.reservation.amount
            : WISH_TYPES_LABELS.NonMonetary}
        </b>
      </td>
      <Description>{props.reservation.description}</Description>
    </tr>
  );
};

export default WishReservationEntry;
WishReservationEntry.propTypes = {
  reservation: PropTypes.object.isRequired
};
