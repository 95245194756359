import React, { Component } from "react";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Overlay = styled.div`
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
`;

const ModalWrapper = styled.div`
  z-index: 200;
  position: relative;
  width: 800px;
  height: 600px;
  background: white;
  left: calc(50% - 400px);
  top: calc(50% - 300px);
  border-radius: 20px;
  overflow-y: scroll;
  @media (min-width: 1200px) {
    width: 1000px;
    height: 800px;
    left: calc(50% - 500px);
    top: calc(50% - 400px);
  }
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    left: 0;
    top: 10px;
  }
`;

const ModalHeader = styled.div`
  top: 0;
  width: 100%;
  height: 70px;
  background: ${props => (props.color ? props.color : COLOR_SCHEME.primary)};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
`;

const Title = styled.div`
  text-align: left;
  flex: 1;
  padding: 0 1rem;
  color: white;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const ModalBody = styled.div`
  padding: 20px;
`;

const CloseButton = styled.div`
  padding-right: 1rem;
  svg {
    &:hover {
      cursor: pointer;
      transform: translateY(-1px);
    }
    transition: ease-in-out all 0.2s;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      showModal: true
    };
  }

  handleClickOutside = event => {
    if (
      this.modalRef.current &&
      !this.modalRef.current.contains(event.target)
    ) {
      this.props.clickOutsideHandler();
    }
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  render() {
    return this.state.showModal ? (
      <Overlay>
        <ModalWrapper ref={this.modalRef}>
          <ModalHeader color={this.props.color}>
            <Title>{this.props.title}</Title>
            <CloseButton>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.props.clickOutsideHandler()}
              />
            </CloseButton>
          </ModalHeader>
          <ModalBody>{this.props.children}</ModalBody>
        </ModalWrapper>
      </Overlay>
    ) : null;
  }
}

export default Modal;
