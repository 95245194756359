import React, { useState } from "react";
import styled from "styled-components";
import { COLOR_SCHEME } from "../Views/Colors";
import { getAlphaColor } from "../Helpers/getAlphaColor";
const FAQItemWrapper = styled.div`
  margin-bottom: 2rem;
`;
const FAQItemHeader = styled.div`
  background: ${COLOR_SCHEME.lightBg};
  color: #777;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
const FAQItemBody = styled.div`
  background: ${getAlphaColor(COLOR_SCHEME.lightBg, 0.3)};
  color: ${COLOR_SCHEME.light};
  padding: ${props => (props.open ? "1rem 1rem" : "0rem 1rem")};
  max-height: ${props => (props.open ? 500 : 0)}px;
  overflow: hidden;
  transition: max-height ease-in-out 0.3s, padding linear 0.3s;
`;

const FAQItem = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <FAQItemWrapper>
      <FAQItemHeader onClick={() => setOpen(!open)}>{title}</FAQItemHeader>
      <FAQItemBody open={open}>{children}</FAQItemBody>
    </FAQItemWrapper>
  );
};

export default FAQItem;
