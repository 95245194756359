import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import CreateWish from "./CreateWish/CreateWish";
import SideNav from "./SideNav";
import ActivateWishlist from "./Settings/Activate";
import ReservedWishes from "./ReservedWishes/ReservedWishes";
import UserAccount from "./User/UserAccount";
import Personalize from "./Personalization/Personalize";
import WishOverview from "./WishOverview/WishOverview";
import { useAuthenticatedUserDetails } from "../../Hooks/useAuthenticatedUserDetails";
import { Loader } from "../Views/Loader";
import TutorialComponent from "./Tutorial/TutorialComponent";
import DashboardCategories from "./Categories/Categories";
import ErrorBoundary from "../Helpers/ErrorBoundary";
import { usePrevious } from "../../Hooks/usePrevious";
import Support from "./SupportChat/Support";

const DashboardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: ${props => (props.noScroll ? "hidden" : "inherit")};
`;
export const ContentWrapper = styled.div`
  padding: 10px;
  flex: 1;
  @media (min-width: 960px) {
    min-width: 710px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  min-width: 0;
`;

const DashboardComponent = props => {
  const [user, loadingUser, errorUser] = useAuthenticatedUserDetails();

  const navigate = nav => {
    props.history.push("/dashboard/" + nav.toLowerCase());
  };

  const prevUser = usePrevious(user);

  /*  // load smallchat
  useEffect(() => {
    window.Smallchat = smallchatConfig;
    const script = document.createElement("script");
    script.src = "https://embed.small.chat/TV208BEU8GV46GGWJJ.js";
    script.id = "smallchat-script";
    script.async = true;
    script.onload = () => {
      const styles = document.createElement("link");
      styles.rel = "stylesheet";
      styles.href = "https://static.small.chat/messenger.css";
      document.head.appendChild(styles);
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://static.small.chat/messenger.js";
      document.body.appendChild(script);
    };
    document.head.appendChild(script);
    return () => {
      const script = document.getElementById("smallchat-script");

      const doc = document.getElementById("Smallchat");
      if (script && doc) {
        doc.remove();
        script.remove();
      }
    };
  }, []);

  // hide smallchat when tutorial is shown

  useEffect(() => {
    if (user && user.showTutorial) {
      const script = document.getElementById("smallchat-script");
      const doc = document.getElementById("Smallchat");
      if (script && doc) {
        doc.remove();
        script.remove();
      }
    }
    if (user && !user.showTutorial && prevUser && prevUser.showTutorial) {
      const script = document.createElement("script");
      script.src = "https://embed.small.chat/TV208BEU8GV46GGWJJ.js";
      script.id = "smallchat-script";
      script.async = true;
      script.onload = () => {
        const styles = document.createElement("link");
        styles.rel = "stylesheet";
        styles.href = "https://static.small.chat/messenger.css";
        document.head.appendChild(styles);
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://static.small.chat/messenger.js";
        document.body.appendChild(script);
      };
      document.head.appendChild(script);
    }
  }, [user]);*/

  // Content Rendering
  const CONTENT_STATE = {
    add: <CreateWish navigate={navigate} uid={user.uid} />,
    wishlist: <WishOverview navigate={navigate} uid={user.uid} />,
    settings: <ActivateWishlist />,
    reserved: <ReservedWishes />,
    user: <UserAccount />,
    personalize: <Personalize />,
    categories: <DashboardCategories uid={user.uid} />,
    support: <Support thread={user.slackThread} />
  };

  if (!loadingUser && errorUser) {
    props.history.push("/");
  }
  return loadingUser ? (
    <Loader />
  ) : (
    <>
      {user.showTutorial && <TutorialComponent uid={user.uid} />}
      <DashboardWrapper noScroll={user.showTutorial}>
        <SideNav update={navigate} activated={user.activated} uid={user.uid} />

        <ContentWrapper>
          <ErrorBoundary>
            {CONTENT_STATE[props.match.params.subPage || "wishlist"]}
          </ErrorBoundary>
        </ContentWrapper>
      </DashboardWrapper>
    </>
  );
};

export default withRouter(DashboardComponent);
