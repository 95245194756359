import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLOR_SCHEME } from "../Views/Colors";

const Wrapper = styled.div`
  width: 100%;
  background: ${COLOR_SCHEME.primary};
  padding-bottom: 30px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  min-height: 80px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;
const Text = styled.div`
  color: white;
`;
const StyledLink = styled(Link)`
  margin: 0 1rem;
  color: white;
  &:hover {
    text-decoration: underline !important;
    color: white !important;
  }
`;
const StyledA = styled.a`
  margin: 0 1rem;
  color: white;
  &:hover {
    text-decoration: underline !important;
    color: white !important;
  }
`;
const LinkWrapper = styled.div`
  color: white;
`;
const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <Wrapper>
      <Container>
        <Text>© {year} Beschenkt.ch</Text>
        <LinkWrapper>
          <StyledLink to={"/about/impressum"}>Impressum</StyledLink>|
          <StyledLink to={"/about/faq"}>FAQ</StyledLink>
          {/*<StyledA href={"https://blog.beschenkt.ch"}>Blog</StyledA>*/}
        </LinkWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footer;
